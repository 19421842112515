import React, { useEffect, useState } from 'react';
import { FormControl, Typography, InputLabel, InputBase, Button, Dialog, DialogContent, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { removeAllProducts, resetDeleteAllProducts } from '../../redux/actions/deleteAllProducts.actions';

const DeleteWithPasswordDialog = ({ dialogState, setDialogState, dialogFor }) => {

    const themeMode = useSelector(state => state.theme.mode);
    const token = useSelector(state => state.auth.token);
    const deleteAllProducts = useSelector(state => state.deleteAllProducts);

    const dispatch = useDispatch();

    const [deleting, setDeleting] = useState(false)
    const [serverMsg, setServerMsg] = useState('')

    const passwordSchema = Yup.object().shape({
        password: Yup.string().required('Required'),
    })

    const handleClose = () => {
        setDialogState({ ...dialogState, [dialogFor]: false, data: null })
    };

    const handleSubmit = (values) => {
        if (dialogFor === 'deleteAllProducts') {
            setDeleting(true);
            dispatch(removeAllProducts(token, values));
        }
    }

    useEffect(() => {
        if (deleteAllProducts.data) {
            handleClose();
            setDeleting(false)
            dispatch(resetDeleteAllProducts());
        } else if (deleteAllProducts.error) {
            setServerMsg('You have entered wrong password')
            setDeleting(false)
        }
    }, [deleteAllProducts]) //eslint-disable-line

    const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
            marginTop: theme.spacing(2),
        },
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: 'transparent',
            border: '1px solid #ced4da',
            fontSize: 11,
            width: '100%',
            padding: '10px 12px',
            ...fontColor,
            transition: theme.transitions.create([
                'border-color',
                'background-color',
            ]),
            fontFamily: 'Inter',
            '&:focus': {
                borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
            },
        },
    }));
    const ipLabel = {
        ...fontColor,
        fontSize: '15px', fontWeight: 'bold', mt: 2
    }
    const warningMsg = { color: 'gray', fontSize: '11px', mt: 2 }

    return (
        <Dialog
            open={dialogState.deleteAllProducts}
            onClose={handleClose}
            PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', width: '400px' } }}
        >
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', ...fontColor }}>
                {deleting ? <Typography sx={{ m: 5, fontSize: '16px', fontWeight: 'bold' }}>Deleting, please wait...</Typography> : <>
                    <Avatar sx={{ bgcolor: '#FEE4E2' }}>
                        <ErrorOutlineIcon color='error' />
                    </Avatar>
                    {dialogFor === 'deleteAllProducts' && <Typography sx={ipLabel}>Delete All Products </Typography>}
                    {dialogFor === 'deleteAllProducts' && <Typography sx={warningMsg}>
                        Are you sure that you want to delete all the products? This cannot be undone. Enter your password to continue.</Typography>}
                    <Formik
                        initialValues={{ password: '' }}
                        validationSchema={passwordSchema}
                        onSubmit={(values) => handleSubmit(values)} >
                        {({ errors, setFieldValue, values }) => (
                            <Form className='loginform'>
                                <FormControl variant='standard' sx={{ width: '100%' }} >
                                    <InputLabel shrink htmlFor='password' sx={{
                                        ...fontColor, fontSize: '11px',
                                        '&.Mui-focused': fontColor
                                    }}>Password
                                    </InputLabel>
                                    <BootstrapInput
                                        type='password'
                                        value={values.password}
                                        placeholder='Enter your password'
                                        id='password'
                                        name='password'
                                        onChange={e => setFieldValue('password', e.target.value)}
                                    />
                                    <Typography sx={{ fontSize: '11px', mt: 1 }} color='red' variant='subtitle'>{errors.password}</Typography>
                                </FormControl>
                                <Typography sx={{ fontSize: '11px', color: 'red' }}>{serverMsg}</Typography>
                                <FormControl sx={{ m: 0, mt: 3, mb: 2, display: 'flex', justifyContent: 'space-between', flexFlow: 'row', gap: '17px' }}>
                                    <Button onClick={() => handleClose()} variant='outlined' sx={{ width: 140, textTransform: 'none', ...fontColor }}>Cancel</Button>
                                    <Button disabled={values.password.length === 0} variant='contained' color='error' sx={{ width: 140, textTransform: 'none' }} type='submit' >Confirm</Button>
                                </FormControl>
                            </Form>
                        )}
                    </Formik></>}
            </DialogContent>
        </Dialog>
    )
}

export default DeleteWithPasswordDialog;