import { RESET_TOGGLE_APPROVAL, TOGGLE_APPROVAL, TOGGLE_APPROVAL_FAILED, TOGGLE_APPROVAL_SUCCESSFULL } from "../constants/toggleApproval.constants";

  const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null,
  };
  
  const toggleApprovalReducer = (state = initialState, action) => {
    switch (action.type) {
      case TOGGLE_APPROVAL:
        return {
          ...state,
          loading: true,
          success: false,
          data: null,
          error: null,
        };
  
      case TOGGLE_APPROVAL_SUCCESSFULL:
        return {
          ...state,
          loading: false,
          success: true,
          data: action.payload,
          error: null,
        };
  
      case TOGGLE_APPROVAL_FAILED:
        return {
          ...state,
          loading: false,
          success: false,
          data: null,
          error: action.payload,
        };
  
      case RESET_TOGGLE_APPROVAL:
        return {
          ...initialState,
        };
  
      default:
        return state;
    }
  };
  
  export default toggleApprovalReducer;
  