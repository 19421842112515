import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_RULES, FETCH_RULES_FAILED, FETCH_RULES_SUCCESSFULL } from "../constants/rules.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const getRules = () => ({
    type: FETCH_RULES
});

const getRulesSuccess = (data) => ({
    type: FETCH_RULES_SUCCESSFULL,
    payload: data
});

const getRulesFailure = (error) => ({
    type: FETCH_RULES_FAILED,
    payload: error
});

export const fetchRules = (token) => async (dispatch) => {
    try {
      dispatch(getRules());
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${paths.modifyRules}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getRulesSuccess(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(handleTokenExpiration());
      } else {
      dispatch(getRulesFailure(error));
      }
    }
};