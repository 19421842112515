import { Route, Routes, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import MyProducts from './pages/MyProducts';
import AddProducts from './pages/AddProducts';
import Report from './pages/Report';
import Settings from './pages/Settings';
import ProductDetails from './pages/ProductDetails';
import Error from './pages/Error';
import Clients from './pages/Clients';
import EditClient from './pages/EditClient';
import AdminComponent from './wrappers/AdminComponent';
import UserComponent from './wrappers/UserComponent';
import CommonComponent from './wrappers/CommonComponent';
import ProductsCreated from './wrappers/ProductsCreated'
import ChangePassword from './pages/ChangePassword';
import ResetPassword from './pages/ResetPassword';

function App() {

  const location = useLocation();

  return (
    <Routes>
      {location.pathname === '/' && <Route path='/' element={<Login />} />}
      <Route path='/login' element={<Login />} />
      <Route path='/resetpassword' element={<ResetPassword />} />
      <Route path='/dashboard' element={<ProductsCreated component={Dashboard} />} />
      <Route path='/myProducts' element={<ProductsCreated component={MyProducts} />} />
      <Route path='/addProducts' element={<UserComponent component={AddProducts} />} />
      <Route path='/report' element={<UserComponent component={Report} />} />
      <Route path='/clients' element={<AdminComponent component={Clients} />} />
      <Route path='/clients/:clientId' element={<AdminComponent component={EditClient} />} />
      <Route path='/settings' element={<UserComponent component={Settings} />} />
      <Route path='/changePassword' element={<UserComponent component={ChangePassword} />} />
      <Route path='/myProduct/:product_id/:location_id/:comp_loc_id' element={<ProductsCreated component={ProductDetails} />} />
      <Route path='*' element={<CommonComponent component={Error} />} />
    </Routes>
  );
}

export default App;