import axios from "axios";
import { FETCH_TIME_ZONES, FETCH_TIME_ZONES_FAILED, FETCH_TIME_ZONES_SUCCESSFULL } from "../constants/timeZones.constants";

const getTimeZones = () => ({
    type: FETCH_TIME_ZONES
});

const getTimeZonesSuccess = (data) => ({
    type: FETCH_TIME_ZONES_SUCCESSFULL,
    payload: data
});

const getTimeZonesFailure = (error) => ({
    type: FETCH_TIME_ZONES_FAILED,
    payload: error
});

export const fetchTimeZones = () => async (dispatch) => {
    try {
      dispatch(getTimeZones());
      const response = await axios.get(`${process.env.REACT_APP_TIME_ZONES_API}`)
      dispatch(getTimeZonesSuccess(response.data));
    } catch (error) {
      dispatch(getTimeZonesFailure(error));
    }
};