import { Box, Button, Dialog, DialogContent, Typography, IconButton } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { resetAuth } from '../../redux/actions/loginUser.actions';
import { resetString } from '../../redux/actions/productString.actions';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

const SessionExpireDialog = () => {

  const session = useSelector(state => state.tokenExpiration.session);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    dispatch(resetAuth());
    dispatch(resetString());
    navigate('/login');
  }

  return (
    <Dialog open={session === 'inactive'}>
      <DialogContent>
        <div style={{display: 'flex', alignItems: 'center'}} >
          <IconButton
           sx={{ backgroundColor: '#fef9c3', cursor: 'inherit',
          '&:hover': {backgroundColor: '#fef9c3',}
          }} >
            <WarningAmberRoundedIcon sx={{color: '#c79f53'}} />
          </IconButton>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'start', pl: '2px' }}>Your session has expired</Typography>
        </div>
        <Typography sx={{ fontSize: '14px', textAlign: 'start', }}>Please log in again to continue using the app.</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}><Button onClick={handleLogout} variant='outlined' sx={{ textTransform: 'none' }}>Log in</Button></Box>
      </DialogContent>
    </Dialog>
  )
}

export default SessionExpireDialog;