import axios from "axios";
import { paths } from '../../Api/paths';
import { RESET_PASSWORD,RESET_PASSWORD_FAILED,RESET_PASSWORD_SUCCESSFULL,RESET_RESET_PASSWORD } from "../constants/resetPasswordLink.constants";

const sendResetPassowrd = () => ({
    type: RESET_PASSWORD
});

const sendResetPassowrdSuccess = (data) => ({
    type: RESET_PASSWORD_SUCCESSFULL,
    payload: data
});

const sendResetPassowrdFailure = (error) => ({
    type: RESET_PASSWORD_FAILED,
    payload: error
});

export const resetResetPassowrd = () => ({
    type: RESET_RESET_PASSWORD
})

export const sendResetPasswordApi = (token, data) => async (dispatch) => {
    try {
      dispatch(sendResetPassowrd());
      const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.resetPassword}?user_id=${token}`,data,
      {})
      dispatch(sendResetPassowrdSuccess(response.data));
    } catch (error) {
        if (error.response) {
            dispatch(sendResetPassowrdFailure(error));
        }
    }
};