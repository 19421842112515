import axios from "axios";
import { paths } from '../../Api/paths';
import { DELETE_GROUP,DELETE_GROUP_FAILED,DELETE_GROUP_SUCCESSFULL,RESET_DELETE_GROUP } from "../constants/deleteGroup.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const deleteGroup = () => ({
    type: DELETE_GROUP
});

const deleteGroupSuccess = (data) => ({
    type: DELETE_GROUP_SUCCESSFULL,
    payload: data
});

const deleteGroupFailure = (error) => ({
    type: DELETE_GROUP_FAILED,
    payload: error
});

export const resetDeleteGroup = () => ({
    type: RESET_DELETE_GROUP
})

export const deleteExistingGroup = (token, group_id) => async (dispatch) => {
    try {
      dispatch(deleteGroup());
      const response = await axios.delete(`${process.env.REACT_APP_API_LINK}${paths.groups}${group_id}`,
      { headers: { Authorization: `bearer ${token}` } });
      dispatch(deleteGroupSuccess(response.data));
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
      dispatch(deleteGroupFailure(error));
          }
    }
};