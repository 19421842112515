import axios from "axios";
import { paths } from "../../Api/paths";
import { ADD_SINGLE_PRODUCT, ADD_SINGLE_PRODUCT_FAILED, ADD_SINGLE_PRODUCT_SUCCESSFULL, RESET_ADD_SINGLE_PRODUCT } from "../constants/addSingleProduct.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const handleSingleProduct = () => ({
    type: ADD_SINGLE_PRODUCT
});

const singleProductSuccessfull = (data) => ({
    type: ADD_SINGLE_PRODUCT_SUCCESSFULL,
    payload: data
});

const singleProductFailed = (error) => ({
    type: ADD_SINGLE_PRODUCT_FAILED,
    payload: error
});

export const resetAddSingleProduct = () => ({
    type: RESET_ADD_SINGLE_PRODUCT
});

export const addSingleProduct = (data, token) => async (dispatch) => {
    try {
        dispatch(handleSingleProduct());
        const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.myProducts}`,
        data, { headers: { Authorization: `bearer ${token}` } })
        dispatch(singleProductSuccessfull(response.data));
    } catch(error){
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
        dispatch(singleProductFailed(error));
          }
    }
}