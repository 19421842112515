import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_PRODUCTS, FETCH_PRODUCTS_FAILED, FETCH_PRODUCTS_SUCCESSFULL } from "../constants/getProducts.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const getProducts = () => ({
    type: FETCH_PRODUCTS
});

const getProductsSuccess = (data) => ({
    type: FETCH_PRODUCTS_SUCCESSFULL,
    payload: data
});

const getProductsFailure = (error) => ({
    type: FETCH_PRODUCTS_FAILED,
    payload: error
});

export const fetchProducts = (token, filterString, qa_user) => async (dispatch) => {
    try {
      dispatch(getProducts());
      let endpoint = qa_user ? paths.qaMyProducts : paths.myProducts;
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${endpoint}${filterString}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getProductsSuccess(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(handleTokenExpiration());
      } else {
      dispatch(getProductsFailure(error));
      }
    }
};