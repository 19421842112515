import { TOKEN_EXPIRED } from "../constants/handleTokenExpiration.constants";
import { LOGIN_SUCCESS, RESET_AUTH } from "../constants/loginUser.constants";

const initialState = {
    session: 'active'
}

const tokenExpirationReducer = (state = initialState, action) => {
    switch (action.type) {

        case TOKEN_EXPIRED:
            return {
                session: 'inactive'
            };

        case RESET_AUTH:
            return {
                session: 'logged_out'
            }

        case LOGIN_SUCCESS:
            return {
                session: 'active'
            }

        default:
            return state;
    }
}

export default tokenExpirationReducer;