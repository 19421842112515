import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const DonutChart = ({ less, more, equal }) => {

  const data = {
    datasets: [
      {
        data: [less, more, equal],
        backgroundColor: ['#3FCD7F', '#FF6767', '#DAC934'],
        hoverBackgroundColor: ['#3FCD7F', '#FF6767', '#DAC934'],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: false,
    maintainAspectRatio: true,
    cutout: '70%',
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <Doughnut
      options={options}
      width={'120%'}
      data={data}
      style={{ marginRight: '1.5em' }}
    />
  );
};

export default DonutChart;