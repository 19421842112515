import { useIdleTimer } from 'react-idle-timer';

const IdleTimer = ({ handleLogout }) => {

    // Function to handle user inactivity
    const handleIdle = () => {
        // Automatically logout the user after 40 miutes of inactivity
        handleLogout();
    };

    // Setting up the idle timer
    useIdleTimer({
        onIdle: handleIdle,
        timeout: 2400000, // 40 minutes in milliseconds
        debounce: 250,
    });

    // This component doesn't render anything
    return null;
};

export default IdleTimer;
