import { TOGGLE_THEME } from '../constants/toggleTheme.constants';

const savedTheme = sessionStorage.getItem('userTheme')
const initialState = { mode: savedTheme || 'light' };

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_THEME:
      return { ...state, mode: state.mode === 'light' ? 'dark' : 'light' };
    default:
      return state;
  }
};

export default themeReducer;