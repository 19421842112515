import { Box, Button, Chip, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import EditNoteIcon from '@mui/icons-material/EditNote';
import moment from 'moment';
import UserActionsDialog from '../UserActionsDialog';
import InfoDialog from '../InfoDialog';

const ManageUsers = () => {

    const themeMode = useSelector(state => state.theme.mode);
    const userRole = useSelector(state => state.auth.role);
    const users = useSelector(state => state.users);

    const [dialogState, setDialogState] = useState({
        addNewSubUser: false,
        editSubUser: false, userRoleInfo: false, data: null
    })

    const openDialog = (type, data) => {
        const tempState = { ...dialogState, [type]: true, data: data }
        setDialogState(tempState);
    }

    const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }

    return (
        <>
            {(dialogState.addNewSubUser || dialogState.editSubUser) && <UserActionsDialog dialogState={dialogState} setDialogState={setDialogState} />}
            {dialogState.userRoleInfo && <InfoDialog dialogState={dialogState} setDialogState={setDialogState} dialogFor='userRoleInfo' />}
            <Grid p={2} mt={4} sx={{ backgroundColor: themeMode === 'light' ? '#fff' : '#232D3B', borderRadius: '10px' }}>
                <Typography sx={{ fontSize: '20px', fontWeight: 'bold', ml: 1 }}>User Management</Typography>
                <Typography sx={{ fontSize: '12px', ml: 1 }}>You can create, remove and set roles for users here.</Typography>
                {users.loading ? <Typography sx={{ fontSize: '16px', display: 'flex', justifyContent: 'center', my: 10 }}>Fetching the users, please wait...</Typography> :
                    users.data ? <Grid mt={3} sx={{ borderRadius: '10px', overflow: 'hidden', border: 1, borderColor: themeMode === 'light' ? 'divider' : 'gray' }}>
                        <Box py={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', ml: 3 }} >Users</Typography>
                                <Chip size='small' variant='outlined' label={`${users.data?.length}`} color='primary' sx={{ ml: 1 }} />
                            </Box>
                            <Button onClick={() => openDialog('addNewSubUser')} variant='contained' sx={{ textTransform: 'none', mx: 2, fontSize: '13px' }} >+ Create New User</Button>
                        </Box>
                        <TableContainer>
                            <Table sx={{ '& .MuiTableCell-root': fontColor }}>
                                <TableHead sx={{ backgroundColor: themeMode === 'dark' ? '#1A222D' : '#FDF8E5' }}>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Phone</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Last Sign in</TableCell>
                                        <TableCell>Role</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.data?.map(user => {
                                        let relativeTime = '';
                                        if (!user?.last_login) {
                                            relativeTime = 'not logged in yet'
                                        } else {
                                            const lastLoggedInTimestamp = user.last_login;
                                            relativeTime = moment(lastLoggedInTimestamp).fromNow();
                                        }
                                        return (
                                            <TableRow key={user?.user_id}>
                                                <TableCell sx={{ textTransform: 'capitalize', fontSize: '13px', p: '10px 0px 10px 16px' }} >{user?.first_name} {user?.last_name}</TableCell>
                                                <TableCell sx={{fontSize: '13px', p: '10px 0px 10px 16px'}} >{user?.email}</TableCell>
                                                <TableCell sx={{fontSize: '13px', p: '10px 0px 10px 16px'}} >{user?.phone_number}</TableCell>
                                                <TableCell sx={{p: '3px 0px 3px 16px'}} >{user?.status ? <Chip sx={{ width: '60px', fontSize: '11px' }} size='small' label='Active' color='success' variant='contained' /> :
                                                    <Chip style={{ width: '60px', fontSize: '11px' }} size='small' label='Inactive' color='error' variant='contained' />}</TableCell>
                                                <TableCell sx={{fontSize: '13px', p: '10px 0px 10px 16px'}} >{relativeTime}</TableCell>
                                                <TableCell sx={{fontSize: '13px', p: '10px 0px 10px 16px'}} >
                                                    <Box sx={{ display: 'flex', alignItems: 'center', textTransform: 'capitalize' }}>
                                                        {user?.roles[0]?.role.role_name}
                                                        {user?.roles[0]?.role.role_name ? <InfoIcon
                                                            style={{ cursor: 'pointer', color: '#1976d2', marginLeft: '5px', fontSize: '20px' }}
                                                            onClick={() => openDialog('userRoleInfo', user)} /> : '-'}
                                                    </Box>
                                                </TableCell>
                                                <TableCell sx={{p: '10px 0px 10px 16px'}} >
                                                    {((userRole === 3 && user?.roles[0]?.role?.role_name !== 'super admin') ||
                                                        (userRole === 4 && user?.roles[0]?.role?.role_name === 'user')) &&
                                                        <IconButton onClick={() => openDialog('editSubUser', user)}>
                                                            <EditNoteIcon style={{...fontColor, fontSize: '20px'}} />
                                                        </IconButton>}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid> :
                        <Typography sx={{ fontSize: '16px', display: 'flex', justifyContent: 'center', my: 10 }}>Failed to fetch the users data, please contact admin for more support</Typography>}
            </Grid>
        </>
    )
}

export default ManageUsers;