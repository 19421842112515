import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, InputBase, FormControl, InputLabel, Box, Dialog, DialogContent } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { addNewSeller, resetAddSeller } from '../../redux/actions/addSeller.actions';
import { fetchSellers } from '../../redux/actions/sellers.actions';
import CloseIcon from '@mui/icons-material/Close';

const AddSellerDialog = ({ handleClose, sellerDialog, loc_id, setFieldValue, activeSalesId }) => {

  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state => state.auth.token);
  const response = useSelector(state => state.addSeller);
  const latestSeller_id = useSelector(state => state.addSeller?.data?.seller_id);

  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false)
  const [serverError, setServereError] = useState('')

  const marketSchema = Yup.object().shape({
    seller_name: Yup.string().required('Seller name is required'),
    seller_rating: Yup.number().min(1, 'Review score must be at least 1').max(5, 'Review score must be at most 5')
      .required('Review score is required').typeError('Review score must be a number'),
    seller_tot_reviews: Yup.number().required('Number of seller reviews is required').typeError('Number of seller reviews must be a number'),
  })

  const handleSubmit = (values) => {
    setSubmitting(true);
    dispatch(addNewSeller(values, token));
  }

  useEffect(() => {
    if (submitting && response.success) {
      handleClose();
      dispatch(fetchSellers(token));
      setSubmitting(false);
      if (latestSeller_id && response.success) {
        setFieldValue(`locations[${activeSalesId}].seller_id`, latestSeller_id);
      }
    } else if (response.error) {
      setServereError('Failed to add new seller')
      setSubmitting(false);
    }
  }, [response]) //eslint-disable-line

  useEffect(() => {
    dispatch(resetAddSeller());
  }, []) //eslint-disable-line

  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
  const formStyle = { width: '100%' };
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '10px 12px',
      ...fontColor,
      transition: theme.transitions.create([
        'border-color',
        'background-color',
      ]),
      fontFamily: 'Inter',
      '&:focus': {
        borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
      },
    },
  }));

  return (
    <div>
      <Dialog
        open={sellerDialog}
        onClose={handleClose}
        PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white' } }}
      >
        <DialogContent sx={fontColor}>
          <Box>
            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='h6' component='h2' style={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', fontSize: '19px' }}>
                Add Seller
              </Typography>
              <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer', fontSize: '20px' }} />
            </Grid>

            {submitting ? <Typography sx={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', mt: 4 }}>Adding a new seller...</Typography> :
              <Grid item>
                <Formik
                  initialValues={{
                    seller_name: '', seller_rating: 5, seller_tot_reviews: 0, loc: loc_id
                  }}
                  validationSchema={marketSchema}
                  onSubmit={(values) => handleSubmit(values)}
                >
                  {({ errors, setFieldValue, values }) => (
                    <Form className='loginform' style={{ width: '100%' }}>
                      <Grid item xs={12} sx={{ display: 'flex', }}>
                        <Grid item xs={12} md={12} xl={12} sx={{ mr: 1 }}>
                          <FormControl variant='standard' style={formStyle}>
                            <InputLabel shrink htmlFor='seller_name' sx={{
                              color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A'
                              , '&.Mui-focused': {
                                color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                              },
                            }}>
                              Seller Name
                            </InputLabel>
                            <BootstrapInput
                              value={values.seller_name}
                              placeholder='Enter Seller name'
                              id='seller_name'
                              onChange={e => setFieldValue('seller_name', e.target.value)}
                            />
                            <Typography color='red' variant='subtitle' sx={{ width: '70%', fontSize: '12px' }}>{errors.seller_name}</Typography>
                          </FormControl>
                          <FormControl id={'rating'} variant='standard' sx={{ mt: 2 }} style={formStyle}>
                            <InputLabel shrink htmlFor='seller_rating' sx={{
                              color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                              '&.Mui-focused': {
                                color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                              },
                            }}>
                              Seller Rating
                            </InputLabel>
                            <BootstrapInput
                              value={values.seller_rating}
                              placeholder='Enter seller rating'
                              id='seller_rating'
                              onChange={e => setFieldValue('seller_rating', e.target.value)}
                            />
                            <Typography color='red' variant='subtitle' sx={{ width: '70%', fontSize: '12px' }}>{errors.seller_rating}</Typography>
                          </FormControl>
                          <FormControl id={'urls'} variant='standard' sx={{ mt: 2 }} style={formStyle}>
                            <InputLabel shrink htmlFor='seller_tot_reviews' sx={{
                              color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                              '&.Mui-focused': {
                                color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                              },
                            }}>
                              Number of Seller Reviews
                            </InputLabel>
                            <BootstrapInput
                              value={values.seller_tot_reviews}
                              placeholder='Enter seller review'
                              id='seller_tot_reviews'
                              onChange={e => setFieldValue('seller_tot_reviews', e.target.value)}
                            />
                            <Typography color='red' variant='subtitle' sx={{ width: '70%', fontSize: '12px' }}>{errors.seller_tot_reviews}</Typography>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <FormControl sx={{ m: 0, mt: 3, mb: 2, width: '100%', alignItems: 'center' }}>
                        <Button type='submit' sx={{ width: '70%', backgroundColor: '#559EFF', borderColor: '#1C3B6E', color: '#000000', textTransform: 'capitalize' }}
                        >Add</Button>
                        {(errors.seller_name) && (!values.seller_name) &&
                          <Typography color='red' variant='subtitle' sx={{ width: '70%', fontSize: '12px' }}>Seller Name is required</Typography>
                        }
                        <Typography color='red' variant='subtitle' sx={{ width: '70%', fontSize: '12px' }}>{serverError}</Typography>
                      </FormControl>
                    </Form>
                  )}
                </Formik>

              </Grid>}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AddSellerDialog;