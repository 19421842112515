import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_LOCATIONS, FETCH_LOCATIONS_FAILED, FETCH_LOCATIONS_SUCCESSFULL } from "../constants/locations.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const getLocations = () => ({
    type: FETCH_LOCATIONS
});

const getLocationsSuccess = (data) => ({
    type: FETCH_LOCATIONS_SUCCESSFULL,
    payload: data
});

const getLocationsFailure = (error) => ({
    type: FETCH_LOCATIONS_FAILED,
    payload: error
});

export const fetchLocations = (token, qa_user, company_id) => async (dispatch) => {
    try {
      dispatch(getLocations());
      const endpoint = qa_user ? `${paths.qaLocations}?company_id=${company_id}` : paths.locations;
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${endpoint}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getLocationsSuccess(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(handleTokenExpiration());
      } else {
      dispatch(getLocationsFailure(error));
      }
    }
};