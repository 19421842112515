import { CLEAR_FILTERS, DETAILS_PAGE_APPLY_FILTERS } from "../constants/detailsPageFilters.constants"

const storedFilters = sessionStorage.getItem('detailPageFilters');
let initialState = null;

if (storedFilters) {
    const storedState = JSON.parse(storedFilters);
    initialState = {
        applied: true,
        ...storedState
    }
} else {
    initialState = {
        applied: false,
        matchRate: '',
        feedbackRange: '',
        marketPlaces: [],
        competitors: []
    }
}

const detailsPageFiltersReducer = (state = initialState, action) => {
    switch (action.type) {

        case DETAILS_PAGE_APPLY_FILTERS:
            return {
                applied: true,
                ...action.payload
            }

        case CLEAR_FILTERS:
            return {
                ...initialState,
                applied: false
            }

        default:
            return state;
    }
}

export default detailsPageFiltersReducer;