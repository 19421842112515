import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_BRANDS, FETCH_BRANDS_FAILED, FETCH_BRANDS_SUCCESSFULL } from "../constants/getBrands.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const getBrands = () => ({
    type: FETCH_BRANDS
});

const getBrandsSuccess = (data) => ({
    type: FETCH_BRANDS_SUCCESSFULL,
    payload: data
});

const getBrandsFailure = (error) => ({
    type: FETCH_BRANDS_FAILED,
    payload: error
});

export const fetchBrands = (token, qa_user, company_id) => async (dispatch) => {
    try {
      dispatch(getBrands());
      const endpoint = qa_user ? `${paths.qaBrands}?company_id=${company_id}` : paths.brands
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${endpoint}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getBrandsSuccess(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(handleTokenExpiration());
      } else {
      dispatch(getBrandsFailure(error));
      }
    }
};