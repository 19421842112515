import axios from "axios";
import { paths } from '../../Api/paths';
import { ADD_RULE, ADD_RULE_FAILED, ADD_RULE_SUCCESSFULL, RESET_ADD_RULE } from "../constants/addRule.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const addRule = () => ({
    type: ADD_RULE
});

const addRuleSuccess = (data) => ({
    type: ADD_RULE_SUCCESSFULL,
    payload: data
});

const addRuleFailure = (error) => ({
    type: ADD_RULE_FAILED,
    payload: error
});

export const resetAddRule = () => ({
    type: RESET_ADD_RULE
})

export const addNewRule = (token, data) => async (dispatch) => {
    try {
      dispatch(addRule());
      const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.modifyRules}`,
      data, { headers: { Authorization: `bearer ${token}`}})
      dispatch(addRuleSuccess(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(handleTokenExpiration());
      } else {
      dispatch(addRuleFailure(error));
      }
    }
};