import axios from "axios";
import { paths } from '../../Api/paths';
import { EDIT_USER, EDIT_USER_FAILED, EDIT_USER_SUCCESSFULL, RESET_EDIT_USER } from "../constants/editUser.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const editUser = () => ({
    type: EDIT_USER
});

const editUserSuccess = (data) => ({
    type: EDIT_USER_SUCCESSFULL,
    payload: data
});

const editUserFailure = (error) => ({
    type: EDIT_USER_FAILED,
    payload: error
});

export const resetEditUser = () => ({
    type: RESET_EDIT_USER
})

export const editSubUser = (token, data, userId) => async (dispatch) => {
    try {
      dispatch(editUser());
      const response = await axios.put(`${process.env.REACT_APP_API_LINK}${paths.users}${userId}/`,
      data, { headers: { Authorization: `bearer ${token}`}})
      dispatch(editUserSuccess(response.data));
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
      dispatch(editUserFailure(error));
          }
    }
};