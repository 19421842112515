import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import RuleActionsDialog from '../RuleActionsDialog';
import InfoDialog from '../InfoDialog';
import DeleteDialog from '../DeleteDialog';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ViewRuleDetails from '../ViewRuleDetails';

const ManageRules = () => {

    const themeMode = useSelector(state => state.theme.mode);
    const rules = useSelector(state => state.rules);

    const [dialogState, setDialogState] = useState({ addNewRule: false, editRule: false, deleteRule: false, groupRuleInfo: false, data: null, viewDetails: false, })

    const openDialog = (grp, type) => {
        const tempState = { ...dialogState, [type]: true, data: grp }
        setDialogState(tempState)
    }

    const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }

    return (
        <>
            {(dialogState.addNewRule || dialogState.editRule) && <RuleActionsDialog dialogState={dialogState} setDialogState={setDialogState} />}
            {dialogState.deleteRule && <DeleteDialog dialogState={dialogState} setDialogState={setDialogState} dialogFor='deleteRule' />}
            {dialogState.groupRuleInfo && <InfoDialog dialogState={dialogState} setDialogState={setDialogState} dialogFor='groupRuleInfo' />}
            {dialogState.viewDetails && <ViewRuleDetails dialogState={dialogState} setDialogState={setDialogState} />}
            <Grid p={2} mt={4} sx={{ backgroundColor: themeMode === 'light' ? '#fff' : '#232D3B', borderRadius: '10px' }}>
                <Grid sx={{ display: "flex", justifyContent: "space-between" }} >
                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold', ml: 1 }}>Rules</Typography>
                    <Button sx={{ textTransform: 'capitalize', fontSize: '13px' }} variant="contained" onClick={() => openDialog(rules?.data, 'addNewRule')}>+ Add Rule</Button>
                </Grid>
                {rules.loading ? <Typography sx={{ fontSize: '16px', display: 'flex', justifyContent: 'center', my: 10 }}>Fetching the groups, please wait...</Typography> :
                    rules.error ? <Typography sx={{ fontSize: '16px', display: 'flex', justifyContent: 'center', my: 10 }}>Failed to fetch the groups data, please contact admin for more support</Typography> :
                        rules.data?.length === 0 ? <Typography sx={{ fontSize: '16px', display: 'flex', justifyContent: 'center', my: 10 }}>No rules added yet</Typography> :
                            <TableContainer sx={{ mt: 3 }}>
                                <Table sx={{ '& .MuiTableCell-root': fontColor }}>
                                    <TableHead sx={{ backgroundColor: themeMode === 'dark' ? '#1A222D' : '#FDF8E5' }}>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>Rule Name</TableCell>
                                            <TableCell>Group Name</TableCell>
                                            <TableCell>No of Market Places</TableCell>
                                            <TableCell>Rules Created on</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rules.data?.map((grp, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell sx={{ p: '0px 0px 0px 16px', fontSize: '13px' }} >{index + 1}</TableCell>
                                                    <TableCell style={{ padding: '0px 0px 0px 16px', fontSize: '13px', whiteSpace: 'pre-wrap', wordWrap: 'break-word', wordBreak: 'break-all', maxWidth: '200px' }}>
                                                    {grp?.rule_name ? grp.rule_name?.charAt(0).toUpperCase() + grp.rule_name.slice(1) : "--"}</TableCell>
                                                    <TableCell sx={{ textTransform: 'capitalize', p: '0px 0px 0px 16px', fontSize: '13px' }} >{grp?.group?.group_name ? grp?.group?.group_name : "--"}</TableCell>
                                                    <TableCell sx={{ p: '0px', pl: 10, fontSize: '13px' }}>{grp?.market_places ? grp?.market_places?.length : "--"}</TableCell>
                                                    <TableCell sx={{fontSize: '13px'}} >
                                                        {grp?.created_date ? new Date(grp.created_date).toLocaleString() : "--"}
                                                    </TableCell>
                                                    <TableCell sx={{p: '0px 0px 0px 0px'}} >
                                                        {grp?.locations?.length > 0 && <><IconButton onClick={() => openDialog(grp.settings_id, 'deleteRule')}>
                                                            <DeleteOutlineOutlinedIcon style={{...fontColor, fontSize: '20px'}} />
                                                        </IconButton>
                                                            <IconButton onClick={() => openDialog(grp, 'editRule')}>
                                                                <EditNoteIcon style={{...fontColor, fontSize: '20px'}} />
                                                            </IconButton>
                                                            <IconButton onClick={() => openDialog(grp, 'viewDetails')}>
                                                                <RemoveRedEyeIcon style={{...fontColor, fontSize: '20px'}} />
                                                            </IconButton></>}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>}
            </Grid>
        </>
    )
}

export default ManageRules;