import { EDIT_COMPETITOR, EDIT_COMPETITOR_FAILED, EDIT_COMPETITOR_SUCCESSFULL, RESET_EDIT_COMPETITOR } from "../constants/editCompetitor.constants";

const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null,
};

const editCompetitorReducer = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_COMPETITOR:
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null,
            };

        case EDIT_COMPETITOR_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };

        case EDIT_COMPETITOR_FAILED:
            return {
                ...state,
                loading: false,
                success: false,
                data: null,
                error: action.payload
            };

        case RESET_EDIT_COMPETITOR:
            return {
                ...initialState
            }

        default:
            return state;
    }
};

export default editCompetitorReducer;