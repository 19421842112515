import { Dialog, DialogContent, Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

const ViewGroupDetails = ({ dialogState, setDialogState }) => {

  const themeMode = useSelector(state => state.theme.mode);

  const handleClose = () => {
    setDialogState((prev) => ({
      ...prev,
      viewDetails: false,
      data: null
    }));
  };

  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
  const valueFont = { fontSize: "12px", fontWeight: "bold", }

  return (
    <Dialog
      open={dialogState.viewDetails}
      onClose={handleClose}
      PaperProps={{
        sx: {
          backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', width: "300px"
        },
      }}
    >
      <DialogContent sx={fontColor} >
        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Group Details</Typography>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer', fontSize: '20px' }} />
        </Grid>
        <Grid sx={{ mt: 3 }} >
          <Typography sx={{ fontSize: '15px', p: 1 }}>
            Product Count: <span style={valueFont} >{dialogState?.data?.product_count}</span>
          </Typography>
          <Typography sx={{ fontSize: '15px', p: 1 }}>
            Group Name: <span style={{ ...valueFont, textTransform: 'capitalize' }} >{dialogState?.data?.group_name}</span>
          </Typography>
          <Typography sx={{ fontSize: '15px', p: 1 }}>
            Group Desc: <span style={valueFont} >{dialogState?.data?.group_desc ? dialogState?.data?.group_desc?.charAt(0)?.toUpperCase() + dialogState?.data?.group_desc?.slice(1) : ''}</span>
          </Typography>
          <Typography sx={{ fontSize: '15px', p: 1 }}>
            Created on: <span style={valueFont} >{dialogState?.data?.created_date ? new Date(dialogState?.data?.created_date)?.toLocaleString() : '--'}</span>
          </Typography>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ViewGroupDetails;