import styled from '@emotion/styled';
import { Box, Button, Checkbox, Grid, InputBase, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik';
import React from 'react'
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

const AddNewEmail = () => {

    const themeMode = useSelector(state => state.theme.mode);

    const initialValues = {
        emails: [{ mail_id: '' }],
        mail_to_admin: false,
        sms_alerts: false,
        adding_new_users: false,
        adding_products: false,
        deleting_all_products: false,
        engine_completion: false,
        adding_individual_products_and_refresh: false,
        application_updates_or_issues: false,
        tech_support_replies: false,
        price_change_notifications_beyond_a_threshold: false,
        others: false
    }

    const NewEmailSchema = Yup.object().shape({
        emails: Yup.array().of(
            Yup.object().shape({
                mail_id: Yup.string().email('Invalid email address').required('Email is required'),
            })
        ),
        mail_to_admin: Yup.boolean(),
        sms_alerts: Yup.boolean(),
        adding_new_users: Yup.boolean(),
        adding_products: Yup.boolean(),
        deleting_all_products: Yup.boolean(),
        engine_completion: Yup.boolean(),
        adding_individual_products_and_refresh: Yup.boolean(),
        application_updates_or_issues: Yup.boolean(),
        tech_support_replies: Yup.boolean(),
        price_change_notifications_beyond_a_threshold: Yup.boolean(),
        others: Yup.boolean(),
    });

    const addEmail = (emails, fieldValue) => {
        emails.push({ 'mail_id': '' })
        fieldValue('emails', emails)
    }

    const removeEmail = (emails, fieldValue, Ind) => {
        const tempMails = emails.filter((mail, index) => index !== Ind);
        fieldValue('emails', tempMails)
    }

    const handleSubmit = (values) => {
        console.log(values);
    }

    const handleClear = (resetForm) => {
        resetForm();
    }

    const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: 'transparent',
            border: '1px solid #ced4da',
            fontSize: 11,
            width: '100%',
            padding: '9px 12px',
            textTransform: 'capitalize',
            ...fontColor,
            transition: theme.transitions?.create([
                'border-color',
                'background-color',
            ]),
            fontFamily: 'Inter',
            '&:focus': {
                borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
            },
        }
    }));

    return (
        <Grid>
            <Formik enableReinitialize initialValues={initialValues}
                validationSchema={NewEmailSchema} onSubmit={(values) => handleSubmit(values)}>
                {({ errors, setFieldValue, values, resetForm }) => (
                    <Form>
                        <Grid my={1} py={1} sx={{ borderBottom: 1, borderColor: themeMode === 'light' ? 'divider' : 'gray' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Email</Typography>
                            <Typography sx={{ fontSize: '12px', marginBottom: 2 }}>Enter the email which you need to get the notifications</Typography>
                            {values?.emails?.map((email, index) => {
                                return (
                                    <div key={index}>
                                        <Box sx={{ marginBottom: 1 }}>
                                            <Field
                                                component={BootstrapInput}
                                                sx={{ width: '300px' }}
                                                id={`email_${index}`}
                                                value={email.mail_id}
                                                onChange={(e) => setFieldValue(`emails[${index}].mail_id`, e.target.value)}
                                                placeholder='Enter email address'
                                            />
                                            {values.emails.length !== 1 && <Button
                                                onClick={() => removeEmail(values.emails, setFieldValue, index)} sx={{ textTransform: 'none', color: 'red' }}>
                                                - Remove email</Button>}
                                            {values.emails.length === (index + 1) && <Button
                                                onClick={() => addEmail(values.emails, setFieldValue)} sx={{ textTransform: 'none' }}>
                                                + Add new email</Button>}
                                        </Box>
                                        {errors.emails && <Typography sx={{ fontSize: '11px', color: 'red' }}>{errors?.emails[index]?.mail_id}</Typography>}
                                    </div>
                                )
                            })}
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: '12px' }} >Need to get a copy of this notification?</Typography>
                                <Checkbox
                                    id='mail_to_admin'
                                    value={values.mail_to_admin}
                                    onChange={(e) => setFieldValue('mail_to_admin', e.target.checked)}
                                    size='small' sx={{ ...fontColor, ml: 2,
                                        '& .MuiSvgIcon-root': { 
                                            fontSize: '18px'
                                        },
                                    }} />
                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Include Me</Typography>
                                <Checkbox
                                    id='sms_alerts'
                                    value={values.sms_alerts}
                                    onChange={(e) => setFieldValue('sms_alerts', e.target.checked)}
                                    size='small' sx={{ ...fontColor, ml: 2,
                                        '& .MuiSvgIcon-root': { 
                                            fontSize: '18px'
                                        },
                                    }} />
                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Get SMS Alerts</Typography>
                            </Box>
                        </Grid>
                        <Grid my={1} py={1} sx={{ borderBottom: 1, borderColor: themeMode === 'light' ? 'divider' : 'gray' }}>
                            <Typography sx={{ fontSize: '13px', fontWeight: 'bold', mb: 2 }}>Get notified for:</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    id='adding_new_users'
                                    value={values.adding_new_users}
                                    onChange={(e) => setFieldValue('adding_new_users', e.target.checked)}
                                    size='small' sx={{...fontColor,
                                        '& .MuiSvgIcon-root': { 
                                            fontSize: '18px'
                                        },
                                    }} />
                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Adding new users</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    id='adding_products'
                                    value={values.adding_products}
                                    onChange={(e) => setFieldValue('adding_products', e.target.checked)}
                                    size='small' sx={{...fontColor,
                                        '& .MuiSvgIcon-root': { 
                                            fontSize: '18px'
                                        },
                                    }} />
                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Adding products</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    id='deleting_all_products'
                                    value={values.deleting_all_products}
                                    onChange={(e) => setFieldValue('deleting_all_products', e.target.checked)}
                                    size='small' sx={{...fontColor,
                                        '& .MuiSvgIcon-root': { 
                                            fontSize: '18px'
                                        },
                                    }} />
                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Deleting all products</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    id='engine_completion'
                                    value={values.engine_completion}
                                    onChange={(e) => setFieldValue('engine_completion', e.target.checked)}
                                    size='small' sx={{...fontColor,
                                        '& .MuiSvgIcon-root': { 
                                            fontSize: '18px'
                                        },
                                    }} />
                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Engine completion (Running/updating competitors)</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    id='adding_individual_products_and_refresh'
                                    value={values.adding_individual_products_and_refresh}
                                    onChange={(e) => setFieldValue('adding_individual_products_and_refresh', e.target.checked)}
                                    size='small' sx={{...fontColor,
                                        '& .MuiSvgIcon-root': { 
                                            fontSize: '18px'
                                        },
                                    }} />
                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Adding individual products and refresh</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    id='application_updates_or_issues'
                                    value={values.application_updates_or_issues}
                                    onChange={(e) => setFieldValue('application_updates_or_issues', e.target.checked)}
                                    size='small' sx={fontColor} />
                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Application updates or issues</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    id='tech_support_replies'
                                    value={values.tech_support_replies}
                                    onChange={(e) => setFieldValue('tech_support_replies', e.target.checked)}
                                    size='small' sx={{...fontColor,
                                        '& .MuiSvgIcon-root': { 
                                            fontSize: '18px'
                                        },
                                    }} />
                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Tech support replies</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    id='price_change_notifications_beyond_a_threshold'
                                    value={values.price_change_notifications_beyond_a_threshold}
                                    onChange={(e) => setFieldValue('price_change_notifications_beyond_a_threshold', e.target.checked)}
                                    size='small' sx={{...fontColor,
                                        '& .MuiSvgIcon-root': { 
                                            fontSize: '18px'
                                        },
                                    }} />
                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Price change notifications beyond a threshold</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    id='others'
                                    value={values.others}
                                    onChange={(e) => setFieldValue('others', e.target.checked)}
                                    size='small' sx={{...fontColor,
                                        '& .MuiSvgIcon-root': { 
                                            fontSize: '18px'
                                        },
                                    }} />
                                <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Others</Typography>
                            </Box>
                        </Grid>
                        <Grid>
                            <Button type='submit' variant='contained' sx={{ textTransform: 'none', mx: 2 }}>Submit</Button>
                            <Button onClick={() => handleClear(resetForm)} variant='outlined' sx={{ textTransform: 'none', ...fontColor }}>Clear</Button>
                        </Grid>
                    </Form>)}
            </Formik>
        </Grid>
    )
}

export default AddNewEmail;