import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Chip, Dialog, DialogContent, Divider, FormControl, Grid, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { clearFilters, detailsPageApplyFilters } from '../../redux/actions/detailsPageFilters.actions';
import DeleteDialog from '../DeleteDialog';
import DeleteIcon from '@mui/icons-material/Delete';

const ProductDetailsFilterDialog = ({ dialogState, setDialogState }) => {

  const themeMode = useSelector(state => state.theme.mode);
  const marketPlaces = useSelector(state => state.marketPlaces.data);
  const filters = useSelector(state => state.detailsPageFilters);
  const deleteSingleCompProductDetails = useSelector(state => state.deleteSingleCompProductDetails);

  const dispatch = useDispatch();

  const percentageValues = Array.from({ length: 20 }, (_, i) => (i + 1) * 5);
  const feedbackRange = ['4 - 5 Star', '3 - 4 Star', '2 - 3 Star', '1 - 2 Star', '0 - 1 Star'];
  const [selectedMatchRate, setSelectedMatchRate] = useState(filters.applied ? filters.matchRate : '');
  const [selectedFeedbackRange, setSelectedFedbackrange] = useState(filters.applied ? filters.feedbackRange : '');
  const [selectedMarketChips, setSelectedMarketChips] = useState(filters.applied ? filters.marketPlaces : marketPlaces.map(mkt => mkt.market_place_id));
  const [expandedMarketplaces, setExpandedMarketplaces] = useState([]);
  const [selectedComps, setSelectedComps] = useState(filters.applied ? filters.competitors : []);
  const [dialogCompState, setDialogCompState] = useState({ deleteSingleComp: false, data: null })
  const displayedItems = 10;

  const checkMatchRate = (comp) => {
    if (Math.round(comp.comp_prd_score) >= selectedMatchRate || !selectedMatchRate) return true
    else return false
  }

  const checkFeedback = (comp) => {
    if (!selectedFeedbackRange) return true;
    else if (comp.comp_prd_rating >= 4 && selectedFeedbackRange === '4 - 5 Star') return true;
    else if (comp.comp_prd_rating >= 3 && comp.comp_prd_rating < 4 && selectedFeedbackRange === '3 - 4 Star') return true;
    else if (comp.comp_prd_rating >= 2 && comp.comp_prd_rating < 3 && selectedFeedbackRange === '2 - 3 Star') return true;
    else if (comp.comp_prd_rating >= 1 && comp.comp_prd_rating < 2 && selectedFeedbackRange === '1 - 2 Star') return true;
    else if (comp.comp_prd_rating >= 0 && comp.comp_prd_rating < 1 && selectedFeedbackRange === '0 - 1 Star') return true;
    else return false;
  };

  const marketSortedComps = marketPlaces?.reduce((result, mkt) => {
    const competitorsInMarket = dialogState.openPdtFilter.data?.filter(comp => {
      return (comp.market_places.market_place_id === mkt.market_place_id && checkMatchRate(comp) && checkFeedback(comp))
    });
    result.push({
      market_place_id: mkt.market_place_id,
      market_place_name: mkt.market_place_name,
      competitors: competitorsInMarket,
    });
    return result;
  }, []);

  const handleClosePdtFilterDialog = () => {
    setDialogState(prevState => ({
      ...prevState,
      openPdtFilter: { show: false, data: null }
    }));
  };

  const handleChangeMatchrate = (event) => {
    setSelectedMatchRate(event.target.value);
    setSelectedComps([]);
    setExpandedMarketplaces([]);
  }

  const handleChangeFeedbackRange = (event) => {
    setSelectedFedbackrange(event.target.value);
    setSelectedComps([]);
    setExpandedMarketplaces([]);
  }

  const handleMarketChipClick = (mkt_id) => {
    const currentIndex = selectedMarketChips.indexOf(mkt_id);
    const newSelectedChips = [...selectedMarketChips];

    if (currentIndex === -1) {
      newSelectedChips.push(mkt_id);
    } else {
      newSelectedChips.splice(currentIndex, 1);
    }

    setSelectedMarketChips(newSelectedChips);
    setExpandedMarketplaces([]);
  };

  const handleDelete = (mkt_id) => {
    const newSelectedMarketChips = selectedMarketChips.filter(item => item !== mkt_id);
    setSelectedMarketChips(newSelectedMarketChips);
  };

  const handleCheckbox = (comp_id) => {
    let tempState = []
    if (selectedComps.includes(comp_id)) {
      tempState = selectedComps.filter(comp => comp !== comp_id)
    } else {
      tempState = [...selectedComps, comp_id]
    }
    setSelectedComps(tempState);
  }

  const handleSeeMoreClick = (index) => {
    setExpandedMarketplaces((prev) => [...prev, index]);
  };

  const handleSeeLessClick = (index) => {
    setExpandedMarketplaces((prev) => prev.filter((expandedIndex) => expandedIndex !== index));
  };

  const clearFilter = () => {
    setSelectedMatchRate('');
    setSelectedFedbackrange('');
    setSelectedMarketChips(marketPlaces.map(mkt => mkt.market_place_id));
    setSelectedComps([]);
    setExpandedMarketplaces([]);
    dispatch(clearFilters());
    sessionStorage.removeItem('detailPageFilters');
  }

  const applyFilter = () => {
    const payload = {
      matchRate: selectedMatchRate,
      feedbackRange: selectedFeedbackRange,
      marketPlaces: selectedMarketChips,
      competitors: selectedComps
    }
    sessionStorage.setItem('detailPageFilters', JSON.stringify(payload));
    dispatch(detailsPageApplyFilters(payload));
    handleClosePdtFilterDialog();
  }

  const openDialog = (type, compData) => {
    const tempState = { ...dialogCompState, [type]: true, data: compData };
    setDialogCompState(tempState);
  };

  useEffect(() => {
    if (deleteSingleCompProductDetails.success) {
      handleClosePdtFilterDialog()
    }
  }, [deleteSingleCompProductDetails]) //eslint-disable-line

  const buttonStyle = { textTransform: 'none', boxShadow: 'none', borderRadius: '15px', px: '25px', fontWeight: 'bold', fontSize: '13px' };

  return (
    <>
      {dialogCompState.deleteSingleComp && <DeleteDialog dialogState={dialogCompState} setDialogState={setDialogCompState} dialogFor={'deleteSingleComp'} />}
      <Dialog
        open={dialogState.openPdtFilter.show} onClose={handleClosePdtFilterDialog}
        PaperProps={{ sx: { width: '90%', maxWidth: '90%', m: 0, background: themeMode === 'dark' ? '#232D3B' : 'white', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' } }}
      >
        <DialogContent sx={{ m: 0, p: 0 }} className={`custom-scrollbar-${themeMode}`} >
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '18px', pt: '15px', pb: '15px', fontWeight: 'bold' }} >
            <Typography sx={{ fontWeight: 'bold' }}>Configure Listing</Typography>
            <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '30px' }} >
              {/* <Button color='warning' sx={{ textTransform: 'none', fontSize: '13px' }} onClick={clearFilter} >Clear Filter</Button> */}
              <CloseIcon sx={{ cursor: 'pointer', fontSize: '20px' }} onClick={handleClosePdtFilterDialog} />
            </Grid>
          </Grid>
          <Divider sx={{ borderColor: themeMode === 'dark' ? '#868685' : '' }} />
          <Grid item xs={12} sx={{ p: '18px', pb: '13px', pt: '8px' }}>
            <Typography sx={{ fontSize: '13px' }} >General Settings</Typography>
            <Grid sx={{ display: 'inline-block' }} >
              <Typography sx={{ fontSize: '12px', pb: '3px' }} >Match Rate</Typography>
              <FormControl fullWidth>
                <Select
                  sx={{
                    width: '80px', border: themeMode === 'dark' ? '1px solid #868685' : '1px solid #5A5A5A',
                    '& .MuiOutlinedInput-input': { p: '3px', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', fontSize: '11px' },
                    '& .MuiSelect-icon': {
                      color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                    },
                  }}
                  id='matchRate' label='select match rate'
                  value={selectedMatchRate}
                  onChange={handleChangeMatchrate}
                  inputProps={{ name: 'matchRate' }}
                  MenuProps={{
                    PaperProps: {
                      className: `custom-scrollbar-${themeMode}`,
                      sx: {
                        backgroundColor: themeMode === 'dark' ? '#232d3b' : 'white',
                        color: themeMode === 'dark' ? '#e4e3e3' : 'black',
                        pt: '0px',
                        pb: '0px',
                      },
                    },
                  }}
                >
                  {percentageValues?.map((value, index) => (
                    <MenuItem sx={{ fontSize: '11px', }} id={`menu-item-${index}`} key={value} value={value}>{value}%</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid sx={{ pt: '15px', display: 'inline-block', ml: '15px' }}>
              <Typography sx={{ fontSize: '12px', pb: '3px' }} >Ratings</Typography>
              <FormControl fullWidth>
                <Select
                  sx={{
                    width: '100px', border: themeMode === 'dark' ? '1px solid #868685' : '1px solid #5A5A5A',
                    '& .MuiOutlinedInput-input': { p: '3px', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', fontSize: '11px' },
                    '& .MuiSelect-icon': {
                      color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                    },
                  }}
                  id='feedback' label='select feedback'
                  value={selectedFeedbackRange}
                  onChange={handleChangeFeedbackRange}
                  inputProps={{ name: 'feedbackrange' }}
                  MenuProps={{
                    MenuListProps: {
                      sx: {
                        backgroundColor: themeMode === 'dark' ? '#232d3b' : 'white',
                        color: themeMode === 'dark' ? '#e4e3e3' : 'black',
                        pt: '0px',
                        pb: '0px',
                      },
                    },
                  }}
                >
                  {feedbackRange?.map((value, index) => (
                    <MenuItem sx={{ fontSize: '11px' }} id={`menu-item-${index}`} key={value} value={value}>{value}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Divider sx={{ borderColor: themeMode === 'dark' ? '#868685' : '' }} />
          <Grid item xs={12} sx={{ p: '18px', pb: '10px', pt: '8px' }} >
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid xs={6} item >
                <Typography sx={{ fontSize: '13px' }} >Marketplace's Sourced</Typography>
              </Grid>
              {/* <Grid xs={6} item sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', pr: '18px' }} >
              <ErrorOutlineOutlinedIcon style={{ transform: 'rotate(180deg)', color: '#07bc0c', fontSize: '20px', mr: '18px' }} /> <span style={{ fontSize: '11px', fontStyle: 'italic' }} >Shows Lowest Price within the marketplace</span>
            </Grid> */}
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', gap: '6px', pt: '15px', pb: '8px' }} >
              {marketPlaces?.map(mkt => (
                <Chip
                  sx={{
                    color: selectedMarketChips.includes(mkt.market_place_id) ? themeMode === 'dark' ? '#5A5A5A' : '#5A5A5A' : themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                    backgroundColor: selectedMarketChips.includes(mkt.market_place_id) ? '#f4d35e' : 'transparent',
                    outline: selectedMarketChips.includes(mkt.market_place_id) ? 'none' : `1px solid ${themeMode === 'dark' ? '#E4E3E3' : 'lightGrey'}`,
                    '&:hover': {
                      backgroundColor: selectedMarketChips.includes(mkt.market_place_id) ? '#f4d35e' : 'transparent',
                    },
                    fontSize: '11px', height: '28px'
                  }}
                  key={mkt.market_place_id} label={mkt.market_place_name} onClick={() => handleMarketChipClick(mkt.market_place_id)}
                  onDelete={selectedMarketChips.includes(mkt.market_place_id) ? () => handleDelete(mkt.market_place_id) : null}
                  deleteIcon={selectedMarketChips.includes(mkt.market_place_id) ? <CloseIcon style={{ color: 'black', fontSize: '14px' }} /> : null}
                />
              ))}
            </Grid>
          </Grid>
          <Divider sx={{ borderColor: themeMode === 'dark' ? '#868685' : '' }} />
          <Grid item xs={12} container rowSpacing={3} sx={{ p: 3 }}>
            {selectedMarketChips?.length > 0 ?
              marketSortedComps?.map((mkt, index) => (selectedMarketChips?.includes(mkt.market_place_id) ?
                (<Grid key={mkt.market_place_id} item xs={6} sx={{ pl: 2 }}>
                  <Grid sx={{
                    border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #e5e1e1',
                    width: '95%', borderRadius: '11px 11px 0px 0px'
                  }}>
                    <Grid sx={{
                      background: themeMode === 'dark' ? '#E4E3E3' : '#f8f8f8', borderRadius: '9px 9px 0px 0px',
                      p: '10px', outline: 'none !important', color: themeMode === 'dark' ? '#5A5A5A' : '#757574',
                      borderBottom: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #e5e1e1'
                    }}>
                      <Typography sx={{ textTransform: 'uppercase', fontSize: '13px' }} >{mkt.market_place_name}</Typography>
                    </Grid>
                    {mkt?.competitors?.length === 0 ? <Typography sx={{ m: 1, fontSize: '12px', textAlign: 'center' }}>No Products</Typography> :
                      mkt?.competitors?.slice(0, expandedMarketplaces.includes(index) ? mkt?.competitors?.length : displayedItems)
                        .sort((a, b) => b.comp_prd_score - a.comp_prd_score)
                        .map(comp => {
                          return (
                            <Grid key={comp.comp_prd_id} item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
                              <Grid item xs={1}>
                                <Checkbox sx={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                              '& .MuiSvgIcon-root': { 
                                fontSize: '18px'
                               },  
                              }}
                                  id={`checkbox_${comp.comp_prd_id}`} size='small' checked={selectedComps.includes(comp.comp_prd_id)}
                                  onChange={() => handleCheckbox(comp.comp_prd_id)} />
                              </Grid>
                              <Grid item xs={9} sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                                <Typography sx={{ fontSize: '12px', color: themeMode === 'dark' ? 'white' : '#5A5A5A' }}>{comp.comp_prd_name}</Typography>
                                <Typography sx={{ fontSize: '11px', lineHeight: '1.4', textTransform: 'capitalize' }}>{comp.seller?.seller_name}</Typography>
                              </Grid>
                              <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} >
                                <Typography sx={{ fontSize: '12px', textAlign: 'end', fontWeight: 'bold' }}>{Math.round(comp.comp_prd_score)}%</Typography>
                                <IconButton onClick={() => openDialog('deleteSingleComp', comp)} >
                                  <DeleteIcon sx={{ cursor: 'pointer', fontSize: '20px', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} />
                                </IconButton>
                              </Grid>
                            </Grid>)
                        })}
                    {mkt?.competitors?.length > displayedItems && (
                      <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {expandedMarketplaces.includes(index) ? (
                          <Typography onClick={() => handleSeeLessClick(index)} sx={{ p: '8px', fontSize: '13px', textDecoration: 'underline', color: '#4a89dd', cursor: 'pointer' }}>
                            View Less
                          </Typography>
                        ) : (
                          <Typography onClick={() => handleSeeMoreClick(index)} sx={{ p: '8px', fontSize: '13px', textDecoration: 'underline', color: '#4a89dd', cursor: 'pointer' }}>
                            View More
                          </Typography>
                        )}
                        <Typography sx={{ p: '8px', fontSize: '13px' }}>
                          Showing {expandedMarketplaces.includes(index) ? mkt?.competitors?.length : displayedItems} results
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>) : null)) :
              <Typography sx={{ m: 'auto', mt: 2, fontSize: '13px' }}>Select any one of the marketplaces to see their products</Typography>}
          </Grid>
          <Divider sx={{ borderColor: themeMode === 'dark' ? '#868685' : '' }} />
          <Grid sx={{ display: 'flex', justifyContent: 'flex-end', m: 2 }} >
            <Button color='warning' sx={{ textTransform: 'none', fontSize: '13px', mr: 1 }} onClick={clearFilter} >Clear Filter</Button>
            <Button disabled={selectedComps?.length === 0} variant='contained' onClick={applyFilter}
              sx={{
                ...buttonStyle, color: 'black', background: '#f4d35e',
                '&:hover': { backgroundColor: '#f4d35e', boxShadow: 'none', },
                '&:active': { backgroundColor: '#f4d35e', boxShadow: 'none', },
              }} >Apply Filter</Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ProductDetailsFilterDialog;