import React, { useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import { resetupdateUserStatus, updateExistingUserStatus } from '../../redux/actions/updateUserStatus.actions';
import { fetchClientsData } from '../../redux/actions/clients.actions';

const CompanyUsers = ({ users }) => {

  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state => state.auth.token);
  const updateUserStatus = useSelector(state => state.updateUserStatus);

  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);
  const [status, setStatus] = useState('')

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    dispatch(resetupdateUserStatus());
    setStatus('')
  };

  const handleChangeStatus = (index, newStatus) => {
    const payload = {
      email: users[index].user.email,
      first_name: users[index].user.first_name,
      last_name: users[index].user.last_name,
      id: users[index].user_id,
      phone_number: users[index].user.phone_number,
      remarks: users[index].user.remarks,
      status: newStatus,
      time_zone: users[index].user.time_zone,
      ui_design: users[index].user.ui_design,
      modified_date: users[index].user?.modified_date,
      role_id: users[index].user?.roles?.[0]?.role.role_id
    };
    dispatch(updateExistingUserStatus(payload, token));
    setStatus(index)
  }

  useEffect(() => {
    if (updateUserStatus?.data) {
      setTimeout(() => {
        dispatch(fetchClientsData(token));
        dispatch(resetupdateUserStatus());
      }, 500);
    }
  }, [updateUserStatus]) //eslint-disable-line


  const cellStyle = { fontSize: '11px', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }

  return (
    <div style={{ width: '100%', }}>
      <Accordion sx={{ background: themeMode === 'dark' ? '#232D3B' : 'white', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
        <AccordionSummary aria-controls='panel1a-content' id='panel1a-header' style={{ padding: '12px' }}>
          <Grid container alignItems='center' justifyContent='center'>
            <Typography sx={{ fontSize: '15px', display: 'flex', fontWeight: 'bold' }}>User Details {expanded ? <ExpandLessIcon style={{ fontSize: '20px', fontWeight: 'bold' }} /> : <ExpandMoreIcon style={{ fontSize: '20px' }} />}</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <>
            <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: '20px', background: themeMode === 'dark' ? '#232D3B' : 'white', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}>
              <TableContainer className={`custom-scrollbar-${themeMode}`} sx={{ maxHeight: 440 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold', ...cellStyle }} >Name</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', ...cellStyle }} >Email</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', ...cellStyle }} >User Role</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', ...cellStyle }} >Status</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', ...cellStyle }} >Last Sign in</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', ...cellStyle }} >Permission</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', ...cellStyle }} >Active / Inactive</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users?.map((obj, index) => (
                      <TableRow key={index}>
                        <TableCell sx={cellStyle} >{obj?.user?.first_name}</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', ...cellStyle }}>{obj?.user?.email}</TableCell>
                        <TableCell sx={cellStyle} >{obj?.user?.roles?.[0]?.role?.role_name}</TableCell>
                        <TableCell sx={cellStyle} >{obj?.user?.status?.toString()}</TableCell>
                        <TableCell sx={cellStyle} >{obj?.user?.last_login ? moment(obj.user.last_login).format('YYYY-MM-DD') : '-'}</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', ...cellStyle }}>
                          {obj.user?.roles?.[0]?.role?.role_permission?.[0]?.permission_details?.permission_desc ?
                            obj.user?.roles?.[0]?.role?.role_permission?.[0]?.permission_details?.permission_desc : '-'}
                        </TableCell>
                        <TableCell sx={{ fontSize: '11px' }}>
                          <Box sx={{ display: 'flex' }}>
                            <Switch id={`switch-${obj.id}`} disabled={(updateUserStatus?.loading && status === index)} checked={obj.user.status} onChange={(event) => handleChangeStatus(index, event.target.checked)} />
                            {(updateUserStatus?.loading && status === index) && <Typography sx={cellStyle}>Updating...</Typography>}
                            {(updateUserStatus?.error && status === index) && <Typography sx={{ fontSize: '11px', color: 'red' }}>Failed to update status</Typography>}
                            {(updateUserStatus?.data && status === index) && <Typography sx={cellStyle}>User status updated</Typography>}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        </AccordionDetails>
      </Accordion>
    </div>
  )
};

export default CompanyUsers;