import React from 'react'
import { Dialog, DialogContent, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

const ViewRuleDetails = ({ dialogState, setDialogState }) => {

  const themeMode = useSelector(state => state.theme.mode);

  const binaryStringToWeekdays = (binaryString) => {
    const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const selectedDays = [];

    for (let i = 0; i < binaryString.length; i++) {
      if (binaryString[i] === '1') {
        selectedDays.push(weekDays[i]);
      }
    }

    return selectedDays;
  };

  const handleClose = () => {
    setDialogState((prev) => ({
      ...prev,
      viewDetails: false,
      data: null
    }));
  };

  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
  const valueFont = { fontSize: "12px", fontWeight: "bold" }

  return (
    <Dialog
      open={dialogState.viewDetails}
      onClose={handleClose}
      PaperProps={{
        sx: {
          backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', width: "350px"
        },
      }}
    >
      <DialogContent sx={fontColor} >
        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Rule Details</Typography>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer', fontSize: '20px' }} />
        </Grid>
        <Grid sx={{ mt: 3 }} >
          <Typography sx={{ fontSize: '15px', p: 1 }}>
            Created Date: <span style={valueFont} >{new Date(dialogState?.data?.created_date).toLocaleDateString()}</span>
          </Typography>
          <Typography sx={{ fontSize: '15px', p: 1 }}>
            Number of Marketplaces: <span style={valueFont} >{dialogState?.data?.market_places ? dialogState?.data.market_places?.length : "--"}</span>
          </Typography>
          <Typography sx={{ fontSize: '15px', p: 1 }}>
            Rule Name: <span style={valueFont} >{dialogState?.data?.rule_name}</span>
          </Typography>
          <Typography sx={{ fontSize: '15px', p: 1 }}>
            Repeat on: <span style={valueFont} >{binaryStringToWeekdays(dialogState?.data?.weekday).join(', ')}</span>
          </Typography>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ViewRuleDetails;