import { Grid, Typography } from '@mui/material'
import React from 'react'
import './loading.css'
import { useSelector } from 'react-redux'

const Loading = ({ height }) => {

    const themeMode = useSelector(state => state.theme.mode)

    return (
        <Grid item sx={{ display: 'flex', flexFlow: 'column', justifyContent: 'center', alignItems: 'center', margin: 'auto', minHeight: height }}>
            <div style={{ display: 'flex' }}>
                <div className='wave'></div>
                <div className='wave'></div>
                <div className='wave'></div>
                <div className='wave'></div>
                <div className='wave'></div>
                <div className='wave'></div>
                <div className='wave'></div>
                <div className='wave'></div>
                <div className='wave'></div>
                <div className='wave'></div>
            </div>
            <Typography variant='' component={'p'} sx={{ color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3', fontSize: '13px' }} >
                Loading, Please wait.</Typography>
        </Grid>
    )
}

export default Loading;