import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Stack, IconButton } from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import CircularProgress from '@mui/material/CircularProgress';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch, useSelector } from 'react-redux';
import './bulkUpload.css'
import { addBulkProducts, resetAddBulkProducts } from '../../redux/actions/addBulkProducts.actions';
import { useNavigate } from 'react-router';
import { toggleProductsAdded } from '../../redux/actions/loginUser.actions';

const BulkUpload = () => {

    const themeMode = useSelector(state => state.theme.mode);
    const token = useSelector(state => state.auth.token);
    const productsAdded = useSelector(state => state.addBulkProducts)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [file, setFile] = useState(null);
    const [serverError, setServerError] = useState('');
    const fileTypes = ['XLSX', 'XLS'];

    const handleChange = (file) => {
        setFile(file);
        setServerError('');
    };

    const handleSubmit = (values) => {
        dispatch(addBulkProducts(values, token));
    }

    useEffect(() => {
        if (productsAdded.data) {
            dispatch(resetAddBulkProducts());
            if (productsAdded.data.default_location){
                navigate('/myproducts');
                dispatch(toggleProductsAdded());
            } 
            else navigate('/settings');
        } else if(productsAdded.error) {
            setServerError('Failed to add products, please check the file before you add the products again')
        }
    }, [productsAdded]) //eslint-disable-line

    useEffect(() => {
      setServerError('')
    }, []) //eslint-disable-line
    

    const btnStyle = { fontSize: 12, textDecoration: 'underline', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', textTransform: 'inherit', }

    return (
        <Grid sx={{ mt: 1 }}>
            <Grid item xs={12} sx={{ display: 'flex', pt: 5, flexFlow: 'column', alignItems: 'center', }}>
                {productsAdded?.loading ? <CircularProgress /> : <>
                    <FileUploader
                        multiple={true}
                        handleChange={handleChange}
                        name='file'
                        types={fileTypes}
                    />
                    <Typography variant='body2' component={'p'} sx={{ mt: 2, fontSize: 13, color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}>{file ? `File name: ${file[0].name}` : 'No files uploaded yet'}</Typography>
                    <Button sx={{ width: '40%', mt: 3, backgroundColor: '#559EFF', borderColor: '#1C3B6E', color: '#000000', fontSize: '13px', textTransform: 'capitalize' }}
                        disabled={!file} onClick={e => handleSubmit(file)}
                    >Add products</Button>
                    <Typography sx={{ mt: 2, fontSize: 13, color: 'red' }}>{serverError}</Typography></>}
            </Grid>
            <Grid item xs={12} sx={{ mt: 0 }}>
                <Typography variant='h4' component={'h4'} sx={{ fontSize: 16, fontWeight: 500 }}>Need Help?</Typography>
                <Stack spacing={2} sx={{ mt: 3 }}>
                    <Grid xs={12} item container className='bulkuploadContainer' >
                        <Grid item xs={2} sx={{ textAlign: 'center' }} >
                            <IconButton >
                                <PlayCircleFilledIcon sx={{ color: '#1C3B6E', fontSize: 50 }} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'left' }} >
                            <Typography variant='h4' component={'h4'} sx={{ fontSize: 16, fontWeight: 500 }} > How to prepare your import file.</Typography>
                            <Typography variant='body2' sx={{ fontSize: 11 }} > This video guide will show you how to prepare your import file.</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                            <Button variant='text' sx={{ ...btnStyle, fontSize: 15 }} > View import guide</Button>
                        </Grid>
                    </Grid>

                    <Grid xs={12} item container className='bulkuploadContainer' >
                        <Grid item xs={2} sx={{ textAlign: 'center' }} >
                            <IconButton >
                                <BrowserUpdatedIcon sx={{ color: '#1C3B6E', fontSize: 50 }} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'left' }} >
                            <Typography variant='h4' component={'h4'} sx={{ fontSize: 16, fontWeight: 500 }} > Download sample import file.</Typography>
                            <Typography variant='body2' sx={{ fontSize: 11 }}  > Would you like to see how import file looks like? Download sample file and test import process..</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                            <Button variant='text' sx={{ ...btnStyle, fontSize: 15 }}>
                                <a href='/assets/Bulk_upload_field.xlsx' style={{ textDecoration: 'none', color: 'inherit' }} target='_blank'>
                                    Download sample file
                                </a>
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default BulkUpload;