import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Error from '../../pages/Error';
import { restoreLoginDetails } from '../../redux/actions/loginUser.actions';

const AdminComponent = ({ component: Component }) => {

    const userRole = useSelector(state => state.auth.role);
    const token = useSelector(state => state.auth.token);
    const savedUserDetails = sessionStorage.getItem('userDetails');
    const dispatch = useDispatch();

    if (savedUserDetails && !token) {
        dispatch(restoreLoginDetails(savedUserDetails));
    }

    if (userRole === 1 && token) {
        return <Component />;
    } else {
        return <Error />;
    }
}

export default AdminComponent