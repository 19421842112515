import axios from "axios";
import { paths } from '../../Api/paths';
import { DELETE_SINGLE_COMP_PPRODUCT,DELETE_SINGLE_COMP_PRODUCT_FAILED,DELETE_SINGLE_COMP_PRODUCT_SUCCESSFULL,RESET_DELETE_SINGLE_COMP_PRODUCT } from "../constants/deleteSingleCompProduct.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const deleteSingleCompProduct = () => ({
    type: DELETE_SINGLE_COMP_PPRODUCT
});

const deleteSingleCompProductSuccess = (data) => ({
    type: DELETE_SINGLE_COMP_PRODUCT_SUCCESSFULL,
    payload: data
});

const deleteSingleCompProductFailure = (error) => ({
    type: DELETE_SINGLE_COMP_PRODUCT_FAILED,
    payload: error
});

export const resetDeleteSingleCompProduct = () => ({
    type: RESET_DELETE_SINGLE_COMP_PRODUCT
})

export const deleteExistingCompProduct = (token, comp_prd_id) => async (dispatch) => {
    try {
      dispatch(deleteSingleCompProduct());
      const response = await axios.delete(`${process.env.REACT_APP_API_LINK}${paths.competitors}${comp_prd_id}`,
      { headers: { Authorization: `bearer ${token}` }});
      dispatch(deleteSingleCompProductSuccess(response.data));
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
      dispatch(deleteSingleCompProductFailure(error));
          }
    }
};