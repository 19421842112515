import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Error from '../../pages/Error';
import { restoreLoginDetails } from '../../redux/actions/loginUser.actions';

const CommonComponent = ({ component: Component }) => {

    const token = useSelector(state=> state.auth.token);
    const savedUserDetails = sessionStorage.getItem('userDetails');
    const dispatch = useDispatch();
    
    if (savedUserDetails && !token) {
        dispatch(restoreLoginDetails(savedUserDetails));
    }
        
    if (token) {
        return <Component />;
    } else {
        return <Error />;
    }
}

export default CommonComponent