import {
  ADD_SETTINGS_GROUP,
  ADD_SETTINGS_GROUP_FAILED,
  ADD_SETTINGS_GROUP_SUCCESSFULL,
  RESET_ADD_SETTINGS_GROUP,
} from "../constants/addSettingsGroup.constants";
const initialState = {
  loading: false,
  success: false,
  data: null,
  error: null,
};

const addSettingsGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SETTINGS_GROUP:
      return {
        ...state,
        loading: true,
        success: false,
        data: null,
        error: null,
      };

    case ADD_SETTINGS_GROUP_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        error: null,
      };

    case ADD_SETTINGS_GROUP_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        data: null,
        error: action.payload,
      };

    case RESET_ADD_SETTINGS_GROUP:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default addSettingsGroupReducer;
