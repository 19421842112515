import axios from "axios";
import { paths } from "../../Api/paths";
import { ADD_SETTINGS_GROUP,ADD_SETTINGS_GROUP_FAILED,ADD_SETTINGS_GROUP_SUCCESSFULL,RESET_ADD_SETTINGS_GROUP } from "../constants/addSettingsGroup.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const addSettingsGroup = (data) => ({
  type: ADD_SETTINGS_GROUP,
  payload: data,
});

const addSettingsGroupSuccess = (data) => ({
  type: ADD_SETTINGS_GROUP_SUCCESSFULL,
  payload: data,
});

const addSettingsGroupFailure = (error) => ({
  type: ADD_SETTINGS_GROUP_FAILED,
  payload: error,
});

export const resetAddSettingsGroup = () => ({
  type: RESET_ADD_SETTINGS_GROUP,
});

export const addNewSettingsGroup = (token,data) => async (dispatch) => {
  try {
    dispatch(addSettingsGroup(data));
    const response = await axios.post(
      `${process.env.REACT_APP_API_LINK}${paths.groups}`,
      data,
      { headers: { Authorization: `bearer ${token}` } }
    );
    dispatch(addSettingsGroupSuccess(response.data));
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(handleTokenExpiration());
    } else {
    dispatch(addSettingsGroupFailure(error));
    }
  }
};
