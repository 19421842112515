import axios from "axios";
import { paths } from '../../Api/paths';
import { CHANGE_PASSWORD, CHANGE_PASSWORD_FAILED, CHANGE_PASSWORD_SUCCESSFULL, RESET_CHANGE_PASSWORD } from "../constants/changePassword.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const changePassword = () => ({
    type: CHANGE_PASSWORD
})

const changePasswordSuccessfull = (data) => ({
    type: CHANGE_PASSWORD_SUCCESSFULL,
    payload: data
})

const changePasswordFailure = (error) => ({
    type: CHANGE_PASSWORD_FAILED,
    payload: error
})

export const resetChangePassword = () => ({
    type: RESET_CHANGE_PASSWORD
})

export const changeUserPassword = (token, data) => async (dispatch) => {
    try{
        dispatch(changePassword());
        const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.changePassword}`,
        data, { headers: { Authorization: `bearer ${token}` } })
        dispatch(changePasswordSuccessfull(response.data));
    } catch(error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
        dispatch(changePasswordFailure(error));
          }
    }
}
