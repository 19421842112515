import axios from "axios";
import { FETCH_CURRENCIES, FETCH_CURRENCIES_FAILED, FETCH_CURRENCIES_SUCCESSFULL } from "../constants/currencies.constants";

const getCurrencies = () => ({
    type: FETCH_CURRENCIES
});

const getCurrenciesSuccess = (data) => ({
    type: FETCH_CURRENCIES_SUCCESSFULL,
    payload: data
});

const getCurrenciesFailure = (error) => ({
    type: FETCH_CURRENCIES_FAILED,
    payload: error
});

export const fetchCurrencies = () => async (dispatch) => {
    try {
      dispatch(getCurrencies());
      const response = await axios.get(`${process.env.REACT_APP_CURRENCIES_API}`)
      dispatch(getCurrenciesSuccess(response.data));
    } catch (error) {
      dispatch(getCurrenciesFailure(error));
    }
};