import {
  ADD_COMPETITOR,
  ADD_COMPETITOR_FAILED,
  ADD_COMPETITOR_SUCCESSFULL,
  RESET_ADD_COMPETITOR,
} from "../constants/addCompetitor.constants";

const initialState = {
  loading: false,
  success: false,
  data: null,
  error: null,
};

const addCompetitorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COMPETITOR:
      return {
        ...state,
        loading: true,
        success: false,
        data: null,
        error: null,
      };

    case ADD_COMPETITOR_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        error: null,
      };

    case ADD_COMPETITOR_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        data: null,
        error: action.payload,
      };

    case RESET_ADD_COMPETITOR:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default addCompetitorReducer;
