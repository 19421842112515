import { Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import PrivacyDialog from '../PrivacyDialog';
import { useSelector } from 'react-redux';

const Footer = () => {

  const themeMode = useSelector(state => state.theme.mode);

  const currentYear = new Date().getFullYear();
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && <PrivacyDialog open={open} setOpen={setOpen} />}
      <Grid item xs={12} p={1} container sx={{ height: '10vh' }} className={themeMode === 'light' ? 'lightTheme' : 'darkTheme'}>
        <Grid item xs={3} >
          <div>
            <img src={'/assets/logo.png'} alt='OttixHow' style={{ height: '30px', marginTop: '12px' }} />
          </div>
        </Grid>
        <Grid item xs={6} >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '12px' }} >
            <Typography variant='subtitle1' textAlign='center'
              style={{ fontSize: '12px', color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3' }}>
              &copy; <a href="https://www.orbiosolutions.com/" target="_blank" rel="noopener noreferrer" style={{color: '#559EFF'}}>Orbio Solutions</a> {currentYear} - All rights reserved. </Typography>
          </div>
        </Grid>
        <Grid item xs={3} >
          <div onClick={() => setOpen(true)} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '12px', cursor: 'pointer', textDecoration: 'underline', color: '#559EFF' }}>
            <Typography variant='subtitle1' style={{ fontSize: '12px' }}>Privacy Policy</Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default Footer;