import React from 'react';
import Card from '@mui/material/Card';
import './summaryDetails.css';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

const SummaryDetails = ({ count, color, title }) => {

  const themeMode = useSelector(state => state.theme.mode);

  return (
    <Card
      sx={{
        height: '180px', width: '170px', display: 'flex', justifyContent: 'center', alignItems: 'center',
        flexDirection: 'column', background: themeMode === 'dark' ? '#232D3B' : 'white',
        color: themeMode === 'dark' ? 'white' : 'black', boxShadow: 'none'
      }}>
      <div className='circleSummary' style={{ border: `12px solid ${color}` }}>
        <span className='circlecountSummary'>{count}</span>
      </div>
      <Box mt={1}>
        <span style={{ fontSize: '11px' }}>{title}</span>
      </Box>
    </Card>
  );
}

export default SummaryDetails;