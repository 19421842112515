import axios from 'axios';
import { paths } from '../../Api/paths';
import { PRODUCT_LOCATIONS_FAILED, PRODUCT_LOCATIONS_GET, PRODUCT_LOCATIONS_SUCCESSFULL } from "../constants/productLocations.constants";
import { handleTokenExpiration } from './handleTokenExpiration.actions';

const getProductLocations = () => ({
    type: PRODUCT_LOCATIONS_GET,
});

const getProductLocationsSuccessfull = (data) => ({
    type: PRODUCT_LOCATIONS_SUCCESSFULL,
    payload: data
});

const getProductLocationsFailed = (error) => ({
    type: PRODUCT_LOCATIONS_FAILED,
    payload: error
});

export const getAllProductLocations = (token, qa_user, company_id) => async (dispatch) => {
    try {
        dispatch(getProductLocations());
        const endpoint = qa_user ? `${paths.qaProductLocations}?company_id=${company_id}` : paths.productLocations;
        const response = await axios.get(`${process.env.REACT_APP_API_LINK}${endpoint}`,
            { headers: { Authorization: `bearer ${token}` } })
        dispatch(getProductLocationsSuccessfull(response.data));
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
        dispatch(getProductLocationsFailed(error));
          }
    }
};