import { Button, Dialog, DialogContent, FormControl, InputBase, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { approveProducts, resetApproveProducts } from '../../redux/actions/approveProduct.actions';

const ApproveProductsDialog = ({ dialogState, setDialogState }) => {

  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state => state.auth.token);
  const approvePrdState = useSelector(state => state.approveProducts);
  const company_id = useSelector(state => state.auth.company_id);

  const dispatch = useDispatch();

  const [approving, setApproving] = useState(false);
  const [serverMsg, setServerMsg] = useState('');

  const confirmSchema = Yup.object().shape({
    confirm: Yup.string().required('Required')
      .test('is-delete-confirmed', 'Please enter DELETE to confirm deletion', value => value === 'CONFIRM'),
  })

  const handleClose = () => {
    const tempState = { ...dialogState, approveAll: false, approveSelected: false, data: null };
    setDialogState(tempState)
  }

  const handleSubmit = () => {
    setApproving(true);
    const payload = { prd_id: dialogState.data }
    const queryString = dialogState.approveSelected ? `?company_id=${company_id}` : dialogState.approveAll ? `?company_id=${company_id}&type=all` : '';
    dispatch(approveProducts(token, payload, queryString))
  }

  useEffect(() => {
    if (approvePrdState.success) {
      handleClose();
      setApproving(false);
      dispatch(resetApproveProducts());
    } else if (approvePrdState.error) {
      setServerMsg('Failed to approve products');
      setApproving(false);
    }
  }, [approvePrdState]) //eslint-disable-line

  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
  const BootstrapInput = styled(InputBase)(() => ({
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '10px 12px',
      ...fontColor,
      fontFamily: 'Inter',
      '&:focus': {
        borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
      },
    },
  }));

  return (
    <Dialog open={dialogState.approveAll || dialogState.approveSelected} onClose={handleClose}
      PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white' } }}>
      <DialogContent>
        {approving ? <Typography sx={{ ...fontColor, my: 5 }}>Approving the products, please wait...</Typography> : <>
          {dialogState.approveAll && <Typography sx={fontColor}>Are you sure to approve all products? </Typography>}
          {dialogState.approveSelected && <Typography sx={fontColor}>Are you sure to approve selected products? </Typography>}
          <Formik initialValues={{ confirm: '' }}
            validationSchema={confirmSchema}
            onSubmit={(values) => handleSubmit(values)}>
            {({ errors, setFieldValue, values }) => (
              <Form className='loginform'>
                <FormControl variant='standard' sx={{ width: '100%' }} >
                  <BootstrapInput
                    type='text'
                    value={values.confirm}
                    placeholder='Enter CONFIRM'
                    id='confirm'
                    name='confirm'
                    onChange={e => setFieldValue('confirm', e.target.value)}
                  />
                  <Typography sx={{ fontSize: '11px', mt: 1 }} color='red' variant='subtitle'>{errors.confirm}</Typography>
                </FormControl>
                <Typography sx={{ fontSize: '11px', color: 'red' }}>{serverMsg}</Typography>
                <FormControl sx={{ m: 0, mt: 3, mb: 2, display: 'flex', justifyContent: 'space-between', flexFlow: 'row', gap: '17px' }}>
                  <Button onClick={() => handleClose()} variant='outlined' sx={{ width: 140, textTransform: 'none', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}>Cancel</Button>
                  <Button disabled={errors.confirm || !values.confirm} variant='contained' sx={{ width: 140, textTransform: 'none' }} type='submit' >Confirm</Button>
                </FormControl>
              </Form>
            )}
          </Formik></>}
      </DialogContent>
    </Dialog>
  )
}

export default ApproveProductsDialog;