import { Grid, Typography } from '@mui/material';
import React from 'react';
import './linkedProducts.css'

const LinkedProducts = ({ title, color, count, totalCount }) => {

  const calculateProgressBarWidth = (count) => {
    return `${(count / totalCount) * 100}%`;
  };

  return (
    <Grid container paddingLeft={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Grid item xs={2} >
        <Typography sx={{ fontSize: '11px' }} >{title}</Typography>
      </Grid>
      <Grid item xs={8} style={{ paddingRight: '15px' }} >
        <div className='progress-bar'>
          <div className='progress' style={{ width: calculateProgressBarWidth(count), backgroundColor: color }}></div>
        </div>
      </Grid>
      <Grid item xs={2} >
        <Typography sx={{ fontSize: '11px' }} >{count}</Typography>
      </Grid>
    </Grid>
  );
}

export default LinkedProducts;
