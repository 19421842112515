import React from 'react';
import './error.css'
import ReportIcon from '@mui/icons-material/Report';
import { useSelector } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const Error = () => {

  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state => state.auth.token);

  const navigate = useNavigate();

  const goToPreviousPage = () => {
    navigate(-1);
  }

  return (
    <>
      {token && <Navbar selected='' />}
      <div style={{ height: token ? '79.4vh' : '88vh' }} className={`error-page ${themeMode === 'light' ? 'lightPage' : 'darkPage'}`}>
        <Typography variant='h4' component='h4'>404 Error: Page Not Found</Typography>
        <ReportIcon style={{ fontSize: '10em' }} />
        <Typography>Page you are looking does not exist, please go back to the previous page</Typography>
        <Button sx={{
          backgroundColor: '#559EFF', borderColor: '#1C3B6E', color: '#000000', marginTop: '20px',
          '&:hover': { backgroundColor: '#559EFF', borderColor: '#1C3B6E', color: '#000000' }
        }}
          onClick={goToPreviousPage}>Go Back</Button>
      </div>
      <Footer />
    </>
  );
};

export default Error;