import React, { useState } from 'react'
import { Button, Chip, Dialog, Divider, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import PriceVariationChart from '../Charts/PriceVariationChart';
import PriceVariationChartFilter from '../PriceVariationChartFiler';

const PriceComparisonDialog = ({ dialogState, setDialogState }) => {

  const themeMode = useSelector(state => state.theme.mode);

  let marketplaces = ['Amazon', 'Carrefour', 'Noon', 'Souq'];
  let chartDays = ['1 D', '7 D', '1 M', '6 M', '1 Y'];
  const [selectedMarketChips, setSelectedMarketChips] = useState([]);
  const [selectedChartDaysChip, setSelectedChartDaysChip] = useState([]);
  const [selectFilter, setSelectFilter] = useState(false)


  const handleCloseGraphDialog = () => {
    setDialogState({ ...dialogState, openGrpah: false })
    setSelectFilter(false)
  };

  const handleDelete = (name) => {
    const newSelectedMarketChips = selectedMarketChips.filter(chip => chip !== name);
    setSelectedMarketChips(newSelectedMarketChips);

    const newSelectedChartDaysChip = selectedChartDaysChip.filter(chip => chip !== name);
    setSelectedChartDaysChip(newSelectedChartDaysChip);
  };

  const handleMarketChipClick = (name) => {
    const currentIndex = selectedMarketChips.indexOf(name);
    const newSelectedChips = [...selectedMarketChips];

    if (currentIndex === -1) {
      newSelectedChips.push(name);
    } else {
      newSelectedChips.splice(currentIndex, 1);
    }

    setSelectedMarketChips(newSelectedChips);
  };

  const handleChartDaysChipClick = (name) => {
    const currentIndex = selectedChartDaysChip.indexOf(name);
    const newSelectedChips = [...selectedChartDaysChip];

    if (currentIndex === -1) {
      newSelectedChips.push(name);
    } else {
      newSelectedChips.splice(currentIndex, 1);
    }

    setSelectedChartDaysChip(newSelectedChips);
  };

  const handleSwitchFilter = () => {
    setSelectFilter(true)
  }

  const handleSwitchChart = () => {
    setSelectFilter(false)
  }

  return (
    <>
      <Dialog
        open={dialogState.openGrpah} onClose={handleCloseGraphDialog} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'
        PaperProps={{ style: { width: '90%', maxWidth: '90%', height: '100%', margin: 0, background: themeMode === 'dark' ? '#232D3B' : 'white', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' } }}
      >
        <Grid container sx={{ overflowY: 'auto' }} className={`custom-scrollbar-${themeMode}`} >
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', padding: '18px', fontWeight: 'bold' }} >
            <Typography sx={{ fontWeight: 'bold' }}>Price Comparison Chart</Typography>
            <IconButton onClick={handleCloseGraphDialog} ><CloseIcon sx={{ cursor: 'pointer', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', fontSize: '20px' }} /></IconButton>
          </Grid>
          <Grid item xs={12} ><Divider sx={{ backgroundColor: themeMode === 'dark' ? '#E4E3E3' : 'lighGrey' }} /></Grid>
          <Grid item container xs={12} sx={{ padding: '18px' }} >
            <Grid item xs={6} sx={{ display: 'flex', gap: '12px' }} >
              {marketplaces?.map((obj, index) => (
                <Chip
                  sx={{
                    color: selectedMarketChips.includes(obj) ? themeMode === 'dark' ? '#5A5A5A' : '#5A5A5A' : themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                    backgroundColor: selectedMarketChips.includes(obj) ? '#f4d35e' : 'transparent',
                    outline: selectedMarketChips.includes(obj) ? 'none' : `1px solid ${themeMode === 'dark' ? '#E4E3E3' : 'lightGrey'}`,
                    '&:hover': {
                      backgroundColor: selectedMarketChips.includes(obj) ? '#f4d35e' : 'transparent',
                    },
                  }}
                  key={index} label={obj} onClick={() => handleMarketChipClick(obj)}
                  onDelete={selectedMarketChips.includes(obj) ? () => handleDelete(obj) : null}
                  deleteIcon={selectedMarketChips.includes(obj) ? <CloseIcon style={{ color: 'black', fontSize: '15px' }} /> : null}
                />
              ))}

            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
              {
                selectFilter ? <Button onClick={handleSwitchChart} sx={{ textTransform: 'capitalize', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', fontWeight: 'bold' }} >See Chart</Button>
                  :
                  <Button onClick={handleSwitchFilter} sx={{ textTransform: 'capitalize', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', fontWeight: 'bold' }} >Compare Products</Button>
              }
            </Grid>
          </Grid>
          <Grid item xs={12} ><Divider sx={{ backgroundColor: themeMode === 'dark' ? '#E4E3E3' : 'lighGrey' }} /></Grid>
          <Grid item xs={12} container >
            {
              selectFilter ?
                <PriceVariationChartFilter selectedMarketChips={selectedMarketChips} selectedChartDaysChip={selectedChartDaysChip} />
                :
                <PriceVariationChart />
            }
          </Grid>
          <Grid item xs={12} ><Divider sx={{ backgroundColor: themeMode === 'dark' ? '#E4E3E3' : 'lighGrey' }} /></Grid>
          <Grid item container xs={12} sx={{ padding: '18px', display: 'flex', justifyContent: 'space-between' }} >
            <div style={{ display: 'flex', gap: '12px' }}>
              {chartDays?.map((obj, index) => (
                <Chip
                  sx={{
                    color: selectedChartDaysChip.includes(obj) ? themeMode === 'dark' ? '#5A5A5A' : '#5A5A5A' : themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                    backgroundColor: selectedChartDaysChip.includes(obj) ? '#f4d35e' : 'transparent',
                    outline: selectedChartDaysChip.includes(obj) ? 'none' : `1px solid ${themeMode === 'dark' ? '#E4E3E3' : 'lightGrey'}`,
                    '&:hover': {
                      backgroundColor: selectedChartDaysChip.includes(obj) ? '#f4d35e' : 'transparent',
                    },
                  }}
                  key={index} label={obj} onClick={() => handleChartDaysChipClick(obj)}
                  onDelete={selectedChartDaysChip.includes(obj) ? () => handleDelete(obj) : null}
                  deleteIcon={selectedChartDaysChip.includes(obj) ? <CloseIcon style={{ color: 'black', fontSize: '15px', fontWeight: 'bold', }} /> : null}
                />
              ))}
            </div>
            <div>
              {
                selectFilter ?
                  <div style={{ display: 'flex', gap: '12px' }} >
                    <Button sx={{
                      textTransform: 'capitalize', background: 'none',
                      color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', borderRadius: '15px', paddingLeft: '25px', paddingRight: '25px', fontSize: 'bold',
                    }} >Cancel</Button>
                    <Button variant='contained' sx={{
                      fontWeight: 'bold', textTransform: 'capitalize', background: '#f4d35e',
                      color: '#5a5a5a', borderRadius: '15px', paddingLeft: '25px', paddingRight: '25px', fontSize: 'bold',
                      '&:hover': { backgroundColor: '#f4d35e' },
                      '&:active': { backgroundColor: '#f4d35e' },
                    }} >Apply Filter</Button>
                  </div> :
                  <Button variant='contained' sx={{
                    fontWeight: 'bold', textTransform: 'capitalize', background: '#f4d35e',
                    color: '#5a5a5a', borderRadius: '15px', paddingLeft: '25px', paddingRight: '25px',
                    '&:hover': { backgroundColor: '#f4d35e' },
                    '&:active': { backgroundColor: '#f4d35e' },
                  }} >Refresh</Button>
              }
            </div>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default PriceComparisonDialog;