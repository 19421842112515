import { FETCH_MARKETPLACES, FETCH_MARKETPLACES_FAILED, FETCH_MARKETPLACES_SUCCESSFULL } from "../constants/marketPlaces.constants";


const initialState = {
    loading: false,
    data: null,
    error: null,
};

const marketPlacesReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case FETCH_MARKETPLACES:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FETCH_MARKETPLACES_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            };

        case FETCH_MARKETPLACES_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            };

        default:
            return state;
    }
};

export default marketPlacesReducer;