import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import { Box, Button, Divider, Grid, IconButton, InputBase, Step, StepLabel, Stepper, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { changeUserPassword } from '../../redux/actions/changePassword.actions'
import { resetAuth } from '../../redux/actions/loginUser.actions'

const ChangePassword = () => {

    const themeMode = useSelector(state => state.theme.mode);
    const token = useSelector(state => state.auth.token);
    const passwordChanged = useSelector(state => state.changePassword);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [serverError, setServerError] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [showPassword, setShowPassword] = useState({ current_pwd: false, new_pwd: false, confirm_pwd: false });
    const steps = ['Current password', 'Setup new password'];

    const initialValues = {
        current_pwd: '',
        new_pwd: '',
        confirm_pwd: ''
    }

    const PasswordSchema = Yup.object().shape({
        current_pwd: Yup.string().required('Current password is required'),
        new_pwd: Yup.string().matches(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must contain at least one letter, one number, and one special character'
        ).required('New password is required'),
        confirm_pwd: Yup.string().oneOf([Yup.ref('new_pwd'), null], 'Passwords must match')
            .required('Confirm password is required'),
    });

    const togglePasswordVisibility = (type) => {
        const tempState = { ...showPassword, [type]: !showPassword[type] };
        setShowPassword(tempState);
    }

    const handleNext = () => {
        setServerError(false);
        setActiveStep(1);
    }

    const handleSubmit = (values) => {
        const payload = {
            old_password: values.current_pwd,
            new_password: values.new_pwd
        }
        dispatch(changeUserPassword(token, payload));
    }

    useEffect(() => {
        if (passwordChanged.data?.status === 'success') {
            sessionStorage.clear();
            dispatch(resetAuth());
            navigate('/login');
        } else if (passwordChanged.error) {
            setServerError(true);
            setActiveStep(0);
        }
    }, [passwordChanged]) //eslint-disable-line

    const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        '& .MuiInputBase-input': {
            position: 'relative',
            backgroundColor: 'transparent',
            fontSize: 14,
            width: '100%',
            paddingLeft: '12px',
            margin: 0,
            textTransform: 'capitalize',
            ...fontColor,
            transition: theme.transitions?.create([
                'border-color',
                'background-color',
            ]),
            fontFamily: 'Inter',
        }
    }));

    return (
        <>
            <Navbar selected='' />
            <Grid px={20} py={3} className={`navPage ${themeMode === 'light' ? 'lightPage' : 'darkPage'}`}>
                <Box sx={{ display: 'flex' }}>
                    <ArrowBackIcon sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
                    <Typography sx={{ ml: 2, fontSize: '16px', fontWeight: 'bold' }}>Change Password</Typography>
                </Box>
                <Grid p={2} mt={4} sx={{ display: 'flex', backgroundColor: themeMode === 'light' ? '#fff' : '#232D3B', borderRadius: '10px' }}>
                    <Stepper sx={{ m: 4, width: '200px', height: '100px' }} activeStep={activeStep} orientation='vertical'>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel sx={{
                                    textTransform: 'capitalize',
                                    '& .MuiStepLabel-label':
                                        { color: themeMode === 'dark' ? '#E4E3E3 !important' : '#5A5A5A !important' },
                                    '& .Mui-active': { fontWeight: 'bold !important' }
                                }}
                                >{label}</StepLabel>
                            </Step>))}
                    </Stepper>
                    <Divider orientation='vertical' flexItem color={themeMode === 'light' ? '' : 'gray'} />
                    <Formik enableReinitialize initialValues={initialValues}
                        validationSchema={PasswordSchema} onSubmit={(values) => handleSubmit(values)}>
                        {({ errors, setFieldValue, values }) => (
                            <Form>
                                {activeStep === 0 ?
                                    <Grid m={6} mt={4}>
                                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Enter your current password</Typography>
                                        <Typography sx={{ fontSize: '12px', mt: 2, fontWeight: 'bold' }}>Current Password</Typography>
                                        <Box sx={{ border: '1px solid #ced4da', borderRadius: 1, width: '300px' }}>
                                            <Field
                                                type={showPassword.current_pwd ? 'text' : 'password'}
                                                component={BootstrapInput}
                                                sx={{ width: '250px', mt: 1 }}
                                                id='current_pwd'
                                                value={values.current_pwd}
                                                onChange={(e) => setFieldValue('current_pwd', e.target.value)}
                                                placeholder='Enter current password'
                                                inputProps={{
                                                    style: { textTransform: 'none' }
                                                }}
                                            />
                                            <IconButton onClick={() => togglePasswordVisibility('current_pwd')}>
                                                {showPassword.current_pwd ? <VisibilityOffOutlinedIcon style={fontColor} /> :
                                                    <VisibilityOutlinedIcon style={fontColor} />}
                                            </IconButton>
                                        </Box>
                                        <Typography sx={{ color: '#1976d2', fontSize: '11px', marginLeft: '205px', marginTop: '5px' }}>Forgot Password?</Typography>
                                        <Typography sx={{ color: 'red', fontSize: '11px', marginTop: '15px' }}>{errors.current_pwd}</Typography>
                                        {serverError && <Typography sx={{ color: 'red', fontSize: '11px', marginTop: '15px' }}>Entered password may be wrong, please check</Typography>}
                                        <Button disabled={values.current_pwd === ''} onClick={handleNext} variant='contained' sx={{ mt: 1, textTransform: 'none', width: '300px' }}>Continue</Button>
                                    </Grid> :
                                    <Grid m={6} mt={4}>
                                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Setup new password</Typography>
                                        <Typography sx={{ fontSize: '12px', mt: 2, fontWeight: 'bold' }}>Password</Typography>
                                        <Box sx={{ border: '1px solid #ced4da', borderRadius: 1, width: '300px' }}>
                                            <Field
                                                type={showPassword.new_pwd ? 'text' : 'password'}
                                                component={BootstrapInput}
                                                sx={{ width: '250px', mt: 1 }}
                                                id='new_pwd'
                                                value={values.new_pwd}
                                                onChange={(e) => setFieldValue('new_pwd', e.target.value)}
                                                placeholder='Enter your password'
                                                inputProps={{
                                                    style: { textTransform: 'none' }
                                                }}
                                            />
                                            <IconButton onClick={() => togglePasswordVisibility('new_pwd')}>
                                                {showPassword.new_pwd ? <VisibilityOffOutlinedIcon style={fontColor} /> :
                                                    <VisibilityOutlinedIcon style={fontColor} />}
                                            </IconButton>
                                        </Box>
                                        <Typography sx={{ color: 'red', fontSize: '11px', marginTop: '15px' }}>{errors.new_pwd}</Typography>
                                        <Typography sx={{ fontSize: '12px', mt: 2, fontWeight: 'bold' }}>Confirm password</Typography>
                                        <Box sx={{ border: '1px solid #ced4da', borderRadius: 1, width: '300px' }}>
                                            <Field
                                                type={showPassword.confirm_pwd ? 'text' : 'password'}
                                                component={BootstrapInput}
                                                sx={{ width: '250px', mt: 1 }}
                                                id='confirm_pwd'
                                                value={values.confirm_pwd}
                                                onChange={(e) => setFieldValue('confirm_pwd', e.target.value)}
                                                placeholder='Confirm your password'
                                                inputProps={{
                                                    style: { textTransform: 'none' }
                                                }}
                                            />
                                            <IconButton onClick={() => togglePasswordVisibility('confirm_pwd')}>
                                                {showPassword.confirm_pwd ? <VisibilityOffOutlinedIcon style={fontColor} /> :
                                                    <VisibilityOutlinedIcon style={fontColor} />}
                                            </IconButton>
                                        </Box>
                                        <Typography sx={{ color: 'red', fontSize: '11px', marginTop: '15px' }}>{errors.confirm_pwd}</Typography>
                                        <Button type='submit' disabled={Boolean(errors.new_pwd) || Boolean(errors.confirm_pwd)}
                                            variant='contained' sx={{ mt: 2, textTransform: 'none', width: '300px' }}
                                        >Confirm</Button>
                                    </Grid>}
                            </Form>)}
                    </Formik>
                </Grid>
            </Grid>
            <Footer />
        </>
    )
}

export default ChangePassword;