import axios from "axios";
import { paths } from '../../Api/paths';
import { ADD_DEPARTMENT, ADD_DEPARTMENT_FAILED, ADD_DEPARTMENT_SUCCESSFULL, RESET_ADD_DEPARTMENT } from "../constants/addDepartment.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const addDepartment = (data) => ({
    type: ADD_DEPARTMENT,
    payload: data
});

const addDepartmentSuccess = (data) => ({
    type: ADD_DEPARTMENT_SUCCESSFULL,
    payload: data
});

const addDepartmentFailure = (error) => ({
    type: ADD_DEPARTMENT_FAILED,
    payload: error
});

export const resetAddDepartment = () => ({
    type: RESET_ADD_DEPARTMENT
})

export const addNewDepartment = (data, token) => async (dispatch) => {
    try {
      dispatch(addDepartment(data));
      const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.departments}`,
      data, { headers: { Authorization: `bearer ${token}`}})
      dispatch(addDepartmentSuccess(response.data));
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
            dispatch(addDepartmentFailure(error));
          }
    }
};