import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_GROUPS, FETCH_GROUPS_FAILED, FETCH_GROUPS_SUCCESSFULL } from "../constants/groups.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const getGroups = () => ({
    type: FETCH_GROUPS
});

const getGroupsSuccess = (data) => ({
    type: FETCH_GROUPS_SUCCESSFULL,
    payload: data
});

const getGroupsFailure = (error) => ({
    type: FETCH_GROUPS_FAILED,
    payload: error
});

export const fetchGroups = (token, qa_user, company_id) => async (dispatch) => {
    try {
      dispatch(getGroups());
      const endpoint = qa_user ? `${paths.qaGroups}?company_id=${company_id}` : paths.groups;
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${endpoint}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getGroupsSuccess(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(handleTokenExpiration());
      } else {
      dispatch(getGroupsFailure(error));
      }
    }
};